import _          from 'lodash';
import classNames from 'classnames';
import PropTypes  from '../PropTypes';
import React      from 'react';
import styles     from './styles.module.scss';

class PointSelector extends React.Component {
    getBulletTheme = () => {
        return classNames(
            styles.pointSelectorCircle,
            {
                [styles.pointSelectorCircleSelected]: this.props.active,
            },
        );
    };

    render () {
        return (
            <button
                className={styles.pointSelector}
                onClick={this.props.onClick}
                disabled={this.props.disabled}
            >
                <span className={this.getBulletTheme()} />
            </button>
        );
    }
}

const Component = PointSelector;

Component.propTypes = {
    active:   PropTypes.bool,
    disabled: PropTypes.bool,
    onClick:  PropTypes.func,
    value:    PropTypes.number,
};

Component.defaultProps = {
    active:   false,
    disabled: false,
    onClick:  _.noop,
    value:    0,
};

export default Component;