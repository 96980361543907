import * as Api              from '../../api';
import _                     from 'lodash';
import I18n                  from 'i18next';
import { AlertBoxActions }   from '../actions/alertBox';
import { call }              from 'redux-saga/effects';
import { MotivationActions } from '../actions/motivation';
import { put }               from 'redux-saga/effects';
import { select }            from 'redux-saga/effects';
import { WorkshopActions }   from '../actions/activeWorkshop';
import SelectionHelper       from '../../helper/SelectionHelper';
import MotivationTypes       from '../../constants/MotivationTypes';
import AnswerTypes           from '../../constants/AnswerTypes';

const fetchMotivations = function* () {
    const response = yield call(Api.fetchMotivations);

    if (response.ok) {
        const motivations = response.data;

        yield put(MotivationActions.fetchMotivationSucceeded({
            motivations,
        }));
    } else {
        yield put(MotivationActions.fetchMotivationFailed({
            error:       'error_motivation',
            description: I18n.t('error_motivation_api'),
        }));
    }
};

const fetchMotivationsFailed = function* ({ error, description }) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(error) + ': ' + description,
    }));
};

const postBatch = function* (motivations, participant, workshop, motivationErrors) {
    const motivation = motivations.shift();

    if (
        motivation &&
        !motivation.sent
    ) {
        const response = yield call(
            Api.postMotivation,
            participant.iri,
            workshop.iri,
            motivation.iri,
            motivation.motivationType,
        );

        if (response.ok) {
            const answerType = SelectionHelper.get(
                motivation.motivationType,
                {
                    [MotivationTypes.actual]: AnswerTypes.currentMotivationAnswers,
                    [MotivationTypes.target]: AnswerTypes.futureMotivationAnswers,
                },
            );

            yield put(MotivationActions.markMotivationDataAsPosted({
                motivation: motivation,
                answerType: answerType,
            }));
        } else {
            motivationErrors.push(motivation);

            const description = response.data ? response.data['hydra:description'].replace('participant: ', '') : null;

            yield put(MotivationActions.postMotivationDataFailed({
                error:       'error_motivation',
                description: description ? description : I18n.t('error_motivation_api'),
            }));
        }
    }

    if (motivations.length > 0) {
        yield postBatch(motivations, participant, workshop, motivationErrors);
    } else if (!motivationErrors.length) {
        yield put(MotivationActions.postMotivationDataSucceeded());
    } else {
        yield put(WorkshopActions.postWorkshopDataFailed());
    }
};

const postMotivations = function* () {
    const participant              = yield select(state => state.activeWorkshop.activeWorkshop.participant);
    const workshop                 = yield select(state => state.activeWorkshop.activeWorkshop);
    const currentMotivationAnswers = yield select(state => state.activeWorkshop.userAnswers.currentMotivationAnswers);
    const futureMotivationAnswers  = yield select(state => state.activeWorkshop.userAnswers.futureMotivationAnswers);
    const allCurrentMotivations    = _.flatten(_.map(_.cloneDeep(currentMotivationAnswers.data), 'motivationDatas'));
    const allFutureMotivations     = _.flatten(_.map(_.cloneDeep(futureMotivationAnswers.data), 'motivationDatas'));

    allCurrentMotivations.forEach((motivation) => {
        motivation.motivationType = MotivationTypes.actual;
    });

    allFutureMotivations.forEach((motivation) => {
        motivation.motivationType = MotivationTypes.target;
    });

    const allMotivations            = allCurrentMotivations.concat(allFutureMotivations);
    const filteredMotivationAnswers = _.filter(allMotivations, { selected: true, sent: false });

    yield postBatch(filteredMotivationAnswers, participant, workshop, []);
};

const postMotivationsFailed = function* ({ error, description }) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(error) + ': ' + description,
    }));
};

export default {
    fetchMotivations,
    fetchMotivationsFailed,
    postMotivations,
    postMotivationsFailed,
};
