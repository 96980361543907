import Icon      from '../../components/Icon';
import PropTypes from '../PropTypes';
import React     from 'react';
import styles    from './styles.module.scss';

class HeaderItem extends React.Component {
    render () {
        return (
            <div className={styles.headerItem}>
                <Icon
                    icon={this.props.icon}
                />
                <span>
                    {this.props.text}
                </span>
            </div>
        );
    }
}

const Component = HeaderItem;

Component.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
};

Component.defaultProps = {
    icon: '',
    text: '',
};

export default Component;