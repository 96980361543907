import React           from 'react';
import IconType        from './IconType';
import PropTypes       from '../PropTypes';
import ArrowIcon       from '../../assets/icons/arrow.svg';
import CalendarIcon    from '../../assets/icons/calendar.svg';
import CheckIcon       from '../../assets/icons/check.svg';
import CompanyIcon     from '../../assets/icons/company.svg';
import CrossIcon       from '../../assets/icons/cross.svg';
import EnvelopeIcon    from '../../assets/icons/envelope.svg';
import EyeIcon         from '../../assets/icons/eye.svg';
import HearthIcon      from '../../assets/icons/hearth.svg';
import PlayIcon        from '../../assets/icons/play.svg';
import RocketIcon      from '../../assets/icons/rocket.svg';
import SelectionHelper from '../../helper/SelectionHelper';
import SpinnerIcon     from '../../assets/icons/spinner.svg';
import UserIcon        from '../../assets/icons/user.svg';
import UsersIcon       from '../../assets/icons/users.svg';
import WriteIcon       from '../../assets/icons/write.svg';

class Icon extends React.Component {
    render() {
        const icon = this.renderIcon();

        return icon ? icon : null;
    }

    renderIcon = () => {
        return SelectionHelper.get(
            this.props.icon,
            {
                [IconType.calendar]: <CalendarIcon />,
                [IconType.check]:    <CheckIcon />,
                [IconType.company]:  <CompanyIcon />,
                [IconType.cross]:    <CrossIcon />,
                [IconType.envelope]: <EnvelopeIcon />,
                [IconType.eye]:      <EyeIcon />,
                [IconType.hearth]:   <HearthIcon />,
                [IconType.rocket]:   <RocketIcon />,
                [IconType.user]:     <UserIcon />,
                [IconType.play]:     <PlayIcon onClick={this.props.onClick} />,
                [IconType.users]:    <UsersIcon />,
                [IconType.write]:    <WriteIcon />,
                [IconType.arrow]:    <ArrowIcon />,
                [IconType.spinner]:  <SpinnerIcon />,
            },
        );
    };
}

const Component = Icon;

Component.propTypes = {
    icon:    PropTypes.oneOf(Object.values(IconType)),
    onClick: PropTypes.func,
};

Component.defaultProps = {
    icon:    '',
    onClick: null,
};

export default Component;
