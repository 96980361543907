const english = {
    appropriate:                           'appropriate',
    back:                                  'Back',
    backToLogin:                           'to Login',
    company:                               'Company',
    copyright:                             '© vergissmeinnicht',
    dateFormat:                            'DD/MM/YYYY',
    department:                            'Department',
    eitherAppropriateOrInappropriate:      'either appropriate or inappropriate',
    emailOptional:                         'Email (optional)',
    error:                                 'Error',
    error_404:                             '404',
    error_clone_question:                  'Questions could not be loaded.',
    error_motivation:                      'Values',
    error_motivation_api:                  'Error while loading motivation data.',
    error_motivation_send_failed:          'The values could not be sent to the server.',
    error_multiple_workshop:               'Multiple active workshops found.',
    error_no_active_workshop:              'No active workshop found.',
    error_page_not_found:                  'The page could not be found.',
    error_participant:                     'Participant',
    error_participant_already_exists:      'This participant is already registered and cannot be changed.\n\nIf you did not complete the Neuroplayce, we would like to ask you to register again - e.g. Max Mustermann > New: M. Mustermann',
    error_semiometry:                      'Motives',
    error_semiometry_api:                  'Error while loading semiometry data.',
    error_semiometry_send_failed:          'The motives could not be sent to the server.',
    error_server_offline:                  'The data could not be sent. Server offline?',
    error_workshop:                        'Workshop',
    error_workshops:                       'Workshops',
    fallbackContextQuestion:               'What is the perception of the company {{companyName}}',
    finish:                                'Finish',
    home:                                  'Home',
    howToEvaluate:                         'How to evaluate the {{type}}',
    imprint:                               'Imprint',
    inappropriate:                         'inappropriate',
    inFuture:                              'Future',
    inventory:                             'Inventory',
    inventoryDetails:                      'Inventory details',
    itemNotFound:                          'Item not found',
    login:                                 'Login',
    more:                                  'More',
    motive:                                'Motive',
    motives:                               'Motives',
    name:                                  'Name',
    neuroplayce:                           'Neuroplayce',
    no:                                    'No',
    privacy:                               'Privacy',
    ratherAppropriate:                     'rather appropriate',
    ratherInappropriate:                   'rather inappropriate',
    reloadData:                            'Reload data',
    send:                                  'send',
    skip:                                  'Skip',
    skipAnswer:                            'Nothing applies',
    statusQuo:                             'Presence',
    success:                               'Success',
    success_data_posted:                   'The data has been successfully sent to the server.',
    uploadingData:                         'Uploading data',
    values:                                'Values',
    veryAppropriate:                       'very appropriate',
    veryInappropriate:                     'very inappropriate',
    windowSize:                            'Window size',
    yes:                                   'Yes',
    'Workshop/START_ANSWERING':            'Hat begonnen Antworten auszufüllen.',
    'Workshop/FINISH_ANSWERING':           'Hat das ausfüllen beendet.',
    'Workshop/VIDEO_FINISHED':             'Hat das Video gesehen.',
    'Workshop/POST_WORKSHOP_DATA':         'Hat den Workshop abgeschlossen',
    'Workshop/POST_WORKSHOP_DATA_FAILED':  'Abschließen des Workshops ist fehlgeschlagen.',
    'Workshop/POST_WORKSHOP_DATA_SUCCESS': 'Abschließen des Workshops war erfolgreich.',
    //
    // The following entries are mandatory
    //
    and:       'and',
    oclock:    'o\'clock',
    or:        'or',
    pageTitle: 'Neuroplayce',
    //
    // Non final
    //
};

module.exports = english;
