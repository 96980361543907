import _                    from 'lodash';
import AnswerTypes          from '../../constants/AnswerTypes';
import update               from 'immutability-helper';
import { MotivationTypes }  from '../actions/motivation';
import { ParticipantTypes } from '../actions/participant';
import { SemiometryTypes }  from '../actions/semiometry';
import { WorkshopTypes }    from '../actions/activeWorkshop';
import { NavigationTypes }  from '../actions/navigation';

const initialState = {
    activeWorkshop:     {
        iri:             null,
        date:            null,
        contextQuestion: null,
        customer:        {
            iri:   null,
            title: null,
        },
        participant:     {
            iri:        null,
            name:       null,
            department: null,
        },
        isFinished:      null,
        isPosting:       false,
    },
    userAnswers:        {
        semiometryAnswers:        {
            data:         [],
            isFinished:   null,
            watchedVideo: false,
        },
        currentMotivationAnswers: {
            data:         [],
            isFinished:   null,
            watchedVideo: false,
        },
        futureMotivationAnswers:  {
            data:         [],
            isFinished:   null,
            watchedVideo: true,
        },
    },
    currentAnswerState: {
        finishedAnswerCount: 0,
        answerIndex:         null,
        answeringType:       null,
    },
};

function getAnswerData(state, action) {
    if (action.answerType === AnswerTypes.semiometryAnswers) {
        return action.semiometries;
    }

    return action.motivations;
}

function getSemiometryAnswerDataIndex(state, item) {
    const motivationCategories = state.userAnswers.semiometryAnswers.data;

    return getItemIndex(motivationCategories, item);
}

function getMotivationAnswerDataIndex(state, item, answerType) {
    const answers            = state.userAnswers[answerType].data;
    let currentCategoryIndex = 0;
    let currentIndex         = 0;

    _.some(answers, (motivationCategory) => {
        currentIndex = getItemIndex(motivationCategory.motivationDatas, item);

        if (currentIndex !== -1) {
            return true;
        }

        ++currentCategoryIndex;
    });

    return {
        itemIndex:     currentIndex,
        categoryIndex: currentCategoryIndex,
    };
}

function getNextAnswerIndex(state, action) {
    if (state.currentAnswerState.answeringType === action.answerType) {
        return state.currentAnswerState.answerIndex;
    }

    return 0;
}

function getItemIndex(answers, item) {
    return _.findIndex(answers, (answer) => {
        return answer.iri === item.iri;
    });
}

function isCategoryAnswered(motivationCategory, action) {
    if (action.selected) {
        return true;
    }

    const selectedAnswersCount = _.filter(motivationCategory, (motivationAnswer) => {
        return motivationAnswer === action.motivation && motivationAnswer.selected;
    }).length;

    return Boolean(selectedAnswersCount);
}

export default function (state = initialState, action) {
    switch (action.type) {
        case NavigationTypes.RESET_STATE:
            return update(state, {
                activeWorkshop:     {
                    $set: initialState.activeWorkshop,
                },
                userAnswers:        {
                    $set: initialState.userAnswers,
                },
                currentAnswerState: {
                    $set: initialState.currentAnswerState,
                },
            });

        case WorkshopTypes.FETCH_WORKSHOP_SUCCESS:
            return update(state, {
                activeWorkshop: {
                    $set: action.activeWorkshop,
                },
            });

        case WorkshopTypes.FETCH_WORKSHOPS_SUCCESS:
            return update(state, {
                activeWorkshop: {
                    $set: action.activeWorkshop,
                },
            });

        case ParticipantTypes.CREATE_PARTICIPANT_SUCCESS:
            return update(state, {
                activeWorkshop: {
                    participant: {
                        $set: action.participant,
                    },
                },
            });

        case WorkshopTypes.START_ANSWERING:
            const data           = getAnswerData(state, action);
            const newAnswerIndex = getNextAnswerIndex(state, action);

            const nextStateTransition = {
                currentAnswerState: {
                    answeringType: {
                        $set: action.answerType,
                    },
                    answerIndex:   {
                        $set: newAnswerIndex,
                    },
                },
            };

            if (!state.userAnswers[action.answerType].data.length) {
                nextStateTransition.userAnswers = {
                    [action.answerType]: {
                        data:       {
                            $push: data,
                        },
                        isFinished: {
                            $set: false,
                        },
                    },
                };
            }

            if (!state.currentAnswerState.answerIndex) {
                nextStateTransition.currentAnswerState.answerIndex = {
                    $set: 0,
                };
            }

            return update(state, nextStateTransition);

        case WorkshopTypes.CHANGE_CURRENT_INDEX:
            return update(state, {
                currentAnswerState: {
                    answerIndex: {
                        $set: action.index,
                    },
                },
            });

        case SemiometryTypes.SAVE_SEMIOMETRY_ANSWER:
            return update(state, {
                userAnswers:        {
                    [state.currentAnswerState.answeringType]: {
                        data: {
                            [state.currentAnswerState.answerIndex]: {
                                value: {
                                    $set: action.semiometry.value,
                                },
                                sent:  {
                                    $set: false,
                                },
                            },
                        },
                    },
                },
                currentAnswerState: {
                    answerIndex: {
                        $set: state.currentAnswerState.answerIndex + 1,
                    },
                },
            });

        case MotivationTypes.SAVE_MOTIVATION_ANSWER:
            const isAnswered = isCategoryAnswered(
                state.userAnswers[state.currentAnswerState.answeringType].data[state.currentAnswerState.answerIndex].motivationDatas,
                action,
            );

            return update(state, {
                userAnswers: {
                    [state.currentAnswerState.answeringType]: {
                        data: {
                            [state.currentAnswerState.answerIndex]: {
                                motivationType:  {
                                    $set: action.motivation.motivationType,
                                },
                                motivationDatas: {
                                    [action.index]: {
                                        selected: {
                                            $set: action.selected,
                                        },
                                        sent:     {
                                            $set: false,
                                        },
                                    },
                                },
                                isAnswered:      {
                                    $set: isAnswered,
                                },
                            },
                        },
                    },
                },
            });

        case SemiometryTypes.MARK_SEMIOMETRY_DATA_AS_POSTED:
            const semiometryDataIndex = getSemiometryAnswerDataIndex(state, action.semiometry);

            return update(state, {
                userAnswers: {
                    semiometryAnswers: {
                        data: {
                            [semiometryDataIndex]: {
                                sent: {
                                    $set: true,
                                },
                            },
                        },
                    },
                },
            });

        case MotivationTypes.MARK_MOTIVATION_DATA_AS_POSTED:
            const {
                      categoryIndex,
                      itemIndex,
                  } = getMotivationAnswerDataIndex(
                state,
                action.motivation,
                action.answerType,
            );

            return update(state, {
                userAnswers: {
                    [action.answerType]: {
                        data: {
                            [categoryIndex]: {
                                motivationDatas: {
                                    [itemIndex]: {
                                        sent: {
                                            $set: true,
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            });

        case WorkshopTypes.FINISH_ANSWERING:
            return update(state, {
                userAnswers:        {
                    [state.currentAnswerState.answeringType]: {
                        isFinished: {
                            $set: true,
                        },
                    },
                },
                currentAnswerState: {
                    finishedAnswerCount: {
                        $set: state.currentAnswerState.finishedAnswerCount + 1,
                    },
                    answeringType:       {
                        $set: null,
                    },
                },
            });

        case WorkshopTypes.POST_WORKSHOP_DATA:
            return update(state, {
                activeWorkshop: {
                    isPosting: {
                        $set: true,
                    },
                },
            });

        case WorkshopTypes.POST_WORKSHOP_DATA_FAILED:
            return update(state, {
                activeWorkshop: {
                    isPosting: {
                        $set: false,
                    },
                },
            });

        case WorkshopTypes.POST_WORKSHOP_DATA_SUCCESS:
            return update(state, {
                activeWorkshop: {
                    isFinished: {
                        $set: true,
                    },
                    isPosting:  {
                        $set: false,
                    },
                },
            });

        case WorkshopTypes.VIDEO_FINISHED:
            return update(state, {
                userAnswers: {
                    [state.currentAnswerState.answeringType]: {
                        watchedVideo: {
                            $set: true,
                        },
                    },
                },
            });

        default:
            return state;
    }
}
