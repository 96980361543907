import _                      from 'lodash';
import AlertBoxManager        from '../components/AlertBoxManager';
import AnswerTypes            from '../constants/AnswerTypes';
import Footer                 from '../components/Footer';
import FooterTheme            from '../components/Footer/FooterTheme';
import Header                 from '../components/Header';
import I18n                   from 'i18next';
import IconType               from '../components/Icon/IconType';
import OverviewDashboard      from '../components/OverviewDashboard';
import PageTitleHelper        from '../helper/PageTitle';
import React                  from 'react';
import Routes                 from '../constants/Routes';
import styles                 from '../styles.module.scss';
import UploadLoadingOverlay   from '../components/UploadLoadingOverlay';
import { bindActionCreators } from 'redux';
import { Component }          from 'react';
import { connect }            from 'react-redux';
import { Helmet }             from 'react-helmet';
import { WorkshopActions }    from '../store/actions/activeWorkshop';

class Screen extends Component {
    constructor (props) {
        super(props);

        this.state = {
            cards: [
                {
                    title:      I18n.t('values'),
                    icon:       IconType.hearth,
                    route:      Routes.values,
                    answerType: AnswerTypes.semiometryAnswers,
                },
                {
                    title:      I18n.t('motives'),
                    text:       I18n.t('statusQuo'),
                    icon:       IconType.eye,
                    route:      Routes.motive,
                    answerType: AnswerTypes.currentMotivationAnswers,
                },
                {
                    title:      I18n.t('motives'),
                    text:       I18n.t('inFuture'),
                    icon:       IconType.rocket,
                    route:      Routes.motive,
                    answerType: AnswerTypes.futureMotivationAnswers,
                },
            ],
        };
    }

    render () {
        return (
            <div className={styles.main}>
                <Header />
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('home'))}
                    </title>
                </Helmet>
                <AlertBoxManager />
                <div className={styles.container}>
                    <OverviewDashboard
                        cards={this.state.cards}
                        isFinished={this.props.completelyFinished}
                        stepsDone={this.props.finishedAnswersCount}
                        sendButtonPressed={this.props.postWorkshopData}
                    />
                    <UploadLoadingOverlay />
                </div>
                <Footer
                    theme={FooterTheme.blue}
                />
            </div>
        );
    }
}

const mapStateToProps = state => (
    {
        completelyFinished:   _.get(state.activeWorkshop, 'activeWorkshop.isFinished', false),
        finishedAnswersCount: _.get(state.activeWorkshop, 'currentAnswerState.finishedAnswerCount', 0),
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(WorkshopActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
