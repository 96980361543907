import _                   from 'lodash';
import update              from 'immutability-helper';
import { SemiometryTypes } from '../actions/semiometry';
import { DebugTypes }      from '../actions/debug';
import { NavigationTypes } from '../actions/navigation';

const initialState = {
    semiometries:         [],
    originalSemiometries: [],
};

/**
 * We do this, because the customer wants to iterate the categories to avoid the user to answer each category separately.
 * We show the user the first question of every category, then the second of each category, and wo on.
 *
 * @param motivationCategories
 * @returns {[]}
 */
function getSemiometriesWithoutCategory (motivationCategories) {
    const flattenedSemiometryItems = [];
    const categories               = _.map(_.get(motivationCategories, 'member'), 'semiometryDatas');
    const categoryLength           = categories.length;
    const maxCategoryLength        = _.maxBy(categories, (category) => {
        return category.length;
    }).length;

    _.times(maxCategoryLength, (categoryIndex) => {
        _.times(categoryLength, (index) => {
            const semiometry = categories[index][categoryIndex];

            if (semiometry) {
                flattenedSemiometryItems.push(categories[index][categoryIndex]);
            }
        });
    });

    return flattenedSemiometryItems;
}

function sliceSemiometries (semiometries) {
    return semiometries.slice(0, 5);
}

export default function (state = initialState, action) {
    switch (action.type) {
        case NavigationTypes.RESET_STATE:
            return update(state, {
                semiometries:         {
                    $set: initialState.semiometries,
                },
                originalSemiometries: {
                    $set: initialState.originalSemiometries,
                },
            });

        case SemiometryTypes.FETCH_SEMIOMETRY_SUCCESS:
            return update(state, {
                semiometries: {
                    $set: getSemiometriesWithoutCategory(action.semiometries),
                },
            });

        case DebugTypes.REDUCE_QUESTIONS:
            return update(state, {
                originalSemiometries: {
                    $set: state.semiometries,
                },
                semiometries:         {
                    $set: sliceSemiometries(state.semiometries),
                },
            });

        case DebugTypes.RESTORE_QUESTIONS:
            return update(state, {
                originalSemiometries: {
                    $set: [],
                },
                semiometries:         {
                    $set: state.originalSemiometries,
                },
            });

        default:
            return state;
    }
}