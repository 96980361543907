import React                  from 'react';
import { Component }          from 'react';
import _                      from 'lodash';
import AlertBoxManager        from '../components/AlertBoxManager';
import ColorButton            from '../components/ColorButton';
import ColorButtonTheme       from '../components/ColorButton/ColorButtonTheme';
import ComponentHelper        from '../helper/ComponentHelper';
import Footer                 from '../components/Footer';
import I18n                   from 'i18next';
import IconType               from '../components/Icon/IconType';
import PageTitleHelper        from '../helper/PageTitle';
import SelectInput            from '../components/SelectInput';
import styles                 from '../styles.module.scss';
import TextInput              from '../components/TextInput';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { Helmet }             from 'react-helmet';
import { ParticipantActions } from '../store/actions/participant';
import StringHelper           from '../helper/String';

class Screen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name:       '',
            email:      '',
            department: '',
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Screen,
            nextProps,
            nextState,
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.anonymous &&
            prevProps.anonymous !== this.props.anonymous
        ) {
            this.setState({
                name: StringHelper.getRandomString(),
            });
        }

        if (this.props.departments.length === 1) {
            this.setState({
                department: this.props.departments[0].iri,
            });
        }
    }

    nameChanged = (event) => {
        this.setState({
            name: event.target.value,
        });
    };

    emailChanged = (event) => {
        this.setState({
            email: event.target.value,
        });
    };

    departmentChanged = (event) => {
        this.setState({
            department: event.target.value,
        });
    };

    onFormSubmit = event => {
        event.preventDefault();

        this.props.createParticipant({
            name:       this.state.name,
            email:      this.state.email,
            department: this.state.department,
        });
    };

    render() {
        return (
            <div className={styles.login}>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('login'))}
                    </title>
                </Helmet>
                <AlertBoxManager />
                <div className={styles.innerLoginContainer}>
                    <h1 className={styles.logoBig}>
                        {I18n.t('neuroplayce')}
                    </h1>
                    <div className={styles.loginForm}>
                        {this.renderForm()}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

    renderLoginQuestion = () => {
        if (this.props.loginQuestion) {
            return (
                <p
                    className={styles.loginText}
                    dangerouslySetInnerHTML={{ __html: this.props.loginQuestion }}
                />
            );
        }

        return null;
    };

    renderNameField = () => {
        if (!this.props.anonymous) {
            const label = this.props.nameFieldName || I18n.t('name');

            return (
                <TextInput
                    value={this.state.name}
                    placeholder={label}
                    icon={IconType.user}
                    focus={true}
                    onChange={this.nameChanged}
                />
            );
        }

        return null;
    };

    renderEmailField = () => {
        if (this.props.showEMailField) {
            const label = this.props.emailFieldName || I18n.t('emailOptional');

            return (
                <TextInput
                    value={this.state.email}
                    placeholder={label}
                    icon={IconType.envelope}
                    focus={true}
                    onChange={this.emailChanged}
                />
            );
        }

        return null;
    };

    renderDepartmentField = () => {
        const { departments } = this.props;

        if (departments.length > 1) {
            const label = this.props.departmentFieldName || I18n.t('department');

            return (
                <SelectInput
                    placeholder={label}
                    icon={IconType.users}
                    onChange={this.departmentChanged}
                    options={departments}
                    selected={this.state.department}
                />
            );
        }

        return null;
    };

    renderForm = () => {
        return (
            <form onSubmit={this.onFormSubmit}>
                {this.renderLoginQuestion()}
                <TextInput
                    placeholder={I18n.t('company')}
                    icon={IconType.company}
                    value={this.props.companyName}
                />
                {this.renderNameField()}
                {this.renderEmailField()}
                {this.renderDepartmentField()}
                <ColorButton
                    text={I18n.t('login')}
                    theme={ColorButtonTheme.inverted}
                    hidden={!this.showLoginButton()}
                    onClick={_.noop}
                />
            </form>
        );
    };

    showLoginButton = () => {
        return (
            this.props.companyName &&
            this.props.companyName.length &&
            this.state.name.length &&
            this.state.department.length
        );
    };
}

Screen.renderAffectingProps = [
    'anonymous',
    'companyName',
    'departments',
    'loginQuestion',
    'showEMailField',
    'nameFieldName',
    'emailFieldName',
    'departmentFieldName',
];

Screen.renderAffectingStates = [
    'name',
    'department',
];

const mapStateToProps = state => {
    const activeWorkshop = _.get(state.activeWorkshop, 'activeWorkshop');

    return {
        anonymous:           _.get(activeWorkshop, 'anonymous'),
        companyName:         _.get(activeWorkshop, 'customer.title', ''),
        departments:         _.get(activeWorkshop, 'departments', []),
        loginQuestion:       _.get(activeWorkshop, 'loginQuestion'),
        showEMailField:      _.get(activeWorkshop, 'showEMailField'),
        nameFieldName:       _.get(activeWorkshop, 'nameFieldName'),
        emailFieldName:      _.get(activeWorkshop, 'emailFieldName'),
        departmentFieldName: _.get(activeWorkshop, 'departmentFieldName'),
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(ParticipantActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
