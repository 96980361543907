import classNames      from 'classnames';
import PropTypes       from '../PropTypes';
import React           from 'react';
import SelectionHelper from '../../helper/SelectionHelper';
import styles          from './styles.module.scss';
import TitleTheme      from './TitleTheme';

class Title extends React.Component {
    getTheme = () => {
        return classNames(
            styles.title,
            {
                [styles.titleUppercase]: this.props.uppercase,
            },
            SelectionHelper.get(
                this.props.theme,
                {
                    [TitleTheme.main]: styles.titleMain,
                    [TitleTheme.card]: styles.titleCard,
                },
            ),
        );
    };

    render() {
        return (
            <h2 className={this.getTheme()}>
                {this.renderTitle()}
            </h2>
        );
    }

    renderTitle = () => {
        if (!this.props.prefix) {
            return this.props.text;
        }

        return (
            <>
                {this.props.prefix + ': '}
                <strong>
                    {this.props.text}
                </strong>
            </>
        );
    };
}

const Component = Title;

Component.propTypes = {
    prefix:    PropTypes.string,
    text:      PropTypes.string,
    uppercase: PropTypes.bool,
};

Component.defaultProps = {
    prefix:    '',
    text:      '',
    uppercase: false,
};

export default Component;