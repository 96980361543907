import AlertBoxManager from '../components/AlertBoxManager';
import Footer          from '../components/Footer';
import FooterTheme     from '../components/Footer/FooterTheme';
import Header          from '../components/Header';
import I18n            from 'i18next';
import PageTitleHelper from '../helper/PageTitle';
import React           from 'react';
import styles          from '../styles.module.scss';
import { Component }   from 'react';
import { Helmet }      from 'react-helmet';

class Screen extends Component {
    render () {
        return (
            <div className={styles.main}>
                <Header />
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('home'))}
                    </title>
                </Helmet>
                <AlertBoxManager />
                <div className={styles.container}>
                    <h1>
                        {I18n.t('404')}
                    </h1>
                    <p>
                        {I18n.t('error_page_not_found')}
                    </p>
                </div>
                <Footer
                    theme={FooterTheme.blue}
                />
            </div>
        );
    }
}

export default Screen;
