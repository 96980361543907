import { makeActionCreator } from '../../helper/Store';

export const WorkshopTypes = {
    GET_WORKSHOP:               'Workshop/GET_WORKSHOP',
    FETCH_WORKSHOP:             'Workshop/FETCH_WORKSHOP',
    FETCH_WORKSHOP_FAILED:      'Workshop/FETCH_WORKSHOP_FAILED',
    FETCH_WORKSHOP_SUCCESS:     'Workshop/FETCH_WORKSHOP_SUCCESS',
    FETCH_WORKSHOPS:            'Workshop/FETCH_WORKSHOPS',
    FETCH_WORKSHOPS_FAILED:     'Workshop/FETCH_WORKSHOPS_FAILED',
    FETCH_WORKSHOPS_SUCCESS:    'Workshop/FETCH_WORKSHOPS_SUCCESS',
    CLONE_QUESTIONS:            'Workshop/CLONE_QUESTIONS',
    START_ANSWERING:            'Workshop/START_ANSWERING',
    FINISH_ANSWERING:           'Workshop/FINISH_ANSWERING',
    CHANGE_CURRENT_INDEX:       'Workshop/CHANGE_CURRENT_INDEX',
    POST_WORKSHOP_DATA:         'Workshop/POST_WORKSHOP_DATA',
    POST_WORKSHOP_DATA_FAILED:  'Workshop/POST_WORKSHOP_DATA_FAILED',
    POST_WORKSHOP_DATA_SUCCESS: 'Workshop/POST_WORKSHOP_DATA_SUCCESS',
    VIDEO_FINISHED:             'Workshop/VIDEO_FINISHED',
};

const getWorkshop = makeActionCreator(
    WorkshopTypes.GET_WORKSHOP,
);

const fetchWorkshop = makeActionCreator(
    WorkshopTypes.FETCH_WORKSHOP,
    {
        id: null,
    },
);

const fetchWorkshopFailed = makeActionCreator(
    WorkshopTypes.FETCH_WORKSHOP_FAILED,
    {
        error:       null,
        description: null,
    },
);

const fetchWorkshopSucceeded = makeActionCreator(
    WorkshopTypes.FETCH_WORKSHOP_SUCCESS,
    {
        activeWorkshop: null,
    },
);

const fetchWorkshops = makeActionCreator(
    WorkshopTypes.FETCH_WORKSHOPS,
);

const fetchWorkshopsFailed = makeActionCreator(
    WorkshopTypes.FETCH_WORKSHOPS_FAILED,
    {
        error:       null,
        description: null,
    },
);

const fetchWorkshopsSucceeded = makeActionCreator(
    WorkshopTypes.FETCH_WORKSHOPS_SUCCESS,
    {
        activeWorkshop: null,
    },
);

const cloneQuestions = makeActionCreator(
    WorkshopTypes.CLONE_QUESTIONS,
    {
        answerType: null,
    },
);

const startAnswering = makeActionCreator(
    WorkshopTypes.START_ANSWERING,
    {
        answerType:   null,
        motivations:  null,
        semiometries: null,
    },
);

const finishAnswering = makeActionCreator(
    WorkshopTypes.FINISH_ANSWERING,
);

const changeCurrentIndex = makeActionCreator(
    WorkshopTypes.CHANGE_CURRENT_INDEX,
    {
        index: null,
    },
);

const postWorkshopData = makeActionCreator(
    WorkshopTypes.POST_WORKSHOP_DATA,
);

const postWorkshopDataFailed = makeActionCreator(
    WorkshopTypes.POST_WORKSHOP_DATA_FAILED,
);

const postWorkshopDataSucceeded = makeActionCreator(
    WorkshopTypes.POST_WORKSHOP_DATA_SUCCESS,
);

const videoFinished = makeActionCreator(
    WorkshopTypes.VIDEO_FINISHED,
);

export const WorkshopActions = {
    changeCurrentIndex,
    cloneQuestions,
    fetchWorkshop,
    fetchWorkshopFailed,
    fetchWorkshops,
    fetchWorkshopsFailed,
    fetchWorkshopsSucceeded,
    fetchWorkshopSucceeded,
    finishAnswering,
    getWorkshop,
    postWorkshopData,
    postWorkshopDataFailed,
    postWorkshopDataSucceeded,
    startAnswering,
    videoFinished,
};