import _                      from 'lodash';
import classNames             from 'classnames';
import ColorButton            from '../ColorButton';
import ComponentHelper        from '../../helper/ComponentHelper';
import Countdown              from '../Countdown';
import I18n                   from 'i18next';
import PointSelectorLabel     from '../PointSelectorLabel';
import pointSelectorLabels    from '../../constants/PointSelectors';
import PointSelectorRow       from '../PointSelectorRow';
import Progress               from '../Progress';
import PropTypes              from '../PropTypes';
import React                  from 'react';
import styles                 from './styles.module.scss';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { WorkshopActions }    from '../../store/actions/activeWorkshop';

class MultiplePointSelector extends React.Component {
    shouldComponentUpdate (nextProps, nextState, nextContext) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    attributePressed = (index) => {
        this.props.motiveChanged(pointSelectorLabels[index + 3].value);
    };

    changeAttribute = (index) => {
        this.props.changeCurrentIndex({
            index: index,
        });
    };

    finishAnsweringButtonPressed = () => {
        this.props.finishAnswering();
    };

    render () {
        return (
            <div className={styles.multiplePointSelectorContainer}>
                <div className={styles.multiplePointSelector}>
                    <div className={styles.multiplePointSelectorLabels}>
                        {pointSelectorLabels.map(this.renderLabel)}
                    </div>
                    <div className={styles.multiplePointSelectorItems}>
                        {this.props.attributes.map(this.renderAttribute)}
                        <div className={styles.multiplePointSelectorBullets}>
                            <Countdown />
                            {this.props.attributes.map(this.renderBulletPoints)}
                        </div>
                        <div className={styles.multiplePointSelectorBackground} />
                    </div>
                </div>
                <div className={styles.multiplePointSelectorButtonContainer}>
                    <ColorButton
                        text={I18n.t('finish') + ' >'}
                        disabled={this.props.progress !== 100}
                        onClick={this.finishAnsweringButtonPressed}
                        shadow={true}
                    />
                    <div className={styles.multiplePointSelectorProgressContainer}>
                        <Progress percentage={this.props.progress} />
                    </div>
                </div>
            </div>
        );
    }

    renderAttribute = (attribute, index) => {
        return (
            <PointSelectorRow
                key={'multiPointSelectorAttribute-' + index}
                attribute={attribute}
                active={this.props.currentIndex === index}
                onClick={this.attributePressed}
            />
        );
    };

    renderBulletPoints = (attribute, index) => {
        const isAnswered = 'value' in attribute;
        const classes    = classNames(
            styles.multiplePointSelectorBullet,
            {
                [styles.multiplePointSelectorBulletActive]: isAnswered,
            },
        );

        return (
            <button
                className={classes}
                key={'multiPointSelectorBulletPoint-' + index}
                onClick={() => {
                    this.changeAttribute(index);
                }}
            >
                {
                    isAnswered ? (
                        I18n.t('yes')
                    ) : (
                        I18n.t('no')
                    )
                }
            </button>
        );
    };

    renderLabel = (label, index) => {
        return (
            <PointSelectorLabel
                key={'multiPointSelectorLabel-' + index}
                title={label.title}
                value={label.value}
            />
        );
    };
}

const Component = MultiplePointSelector;

Component.propTypes = {
    attributes:    PropTypes.array,
    currentIndex:  PropTypes.number,
    motiveChanged: PropTypes.func,
    progress:      PropTypes.number,
};

Component.defaultProps = {
    attributes:    [],
    currentIndex:  0,
    motiveChanged: _.noop,
    progress:      0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(WorkshopActions, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(Component);