import _                   from 'lodash';
import update              from 'immutability-helper';
import { MotivationTypes } from '../actions/motivation';
import { DebugTypes }      from '../actions/debug';
import { NavigationTypes } from '../actions/navigation';

const initialState = {
    motivations:         [],
    originalMotivations: [],
};

function getMotivationsWithoutCategory (motivationCategories) {
    const categories = _.get(motivationCategories, 'member');

    return categories;
}

function sliceMotivations (categories) {
    return categories.slice(0, 5);
}

export default function (state = initialState, action) {
    switch (action.type) {
        case NavigationTypes.RESET_STATE:
            return update(state, {
                motivations:         {
                    $set: initialState.motivations,
                },
                originalMotivations: {
                    $set: initialState.originalMotivations,
                },
            });

        case MotivationTypes.FETCH_MOTIVATION_SUCCESS:
            return update(state, {
                motivations: {
                    $set: getMotivationsWithoutCategory(action.motivations),
                },
            });

        case DebugTypes.REDUCE_QUESTIONS:
            return update(state, {
                originalMotivations: {
                    $set: state.motivations,
                },
                motivations:         {
                    $set: sliceMotivations(state.motivations),
                },
            });

        case DebugTypes.RESTORE_QUESTIONS:
            return update(state, {
                originalMotivations: {
                    $set: [],
                },
                motivations:         {
                    $set: state.originalMotivations,
                },
            });

        default:
            return state;
    }
}