import { makeActionCreator } from '../../helper/Store';

export const SemiometryTypes = {
    FETCH_SEMIOMETRY:               'Semiometry/FETCH_SEMIOMETRY',
    FETCH_SEMIOMETRY_FAILED:        'Semiometry/FETCH_SEMIOMETRY_FAILED',
    FETCH_SEMIOMETRY_SUCCESS:       'Semiometry/FETCH_SEMIOMETRY_SUCCESS',
    SAVE_SEMIOMETRY_ANSWER:         'Semiometry/SAVE_SEMIOMETRY_ANSWER',
    POST_SEMIOMETRY_DATA:           'Semiometry/POST_SEMIOMETRY_DATA',
    POST_SEMIOMETRY_DATA_FAILED:    'Semiometry/POST_SEMIOMETRY_DATA_FAILED',
    POST_SEMIOMETRY_DATA_SUCCESS:   'Semiometry/POST_SEMIOMETRY_DATA_SUCCESS',
    MARK_SEMIOMETRY_DATA_AS_POSTED: 'Semiometry/MARK_SEMIOMETRY_DATA_AS_POSTED',
};

const fetchSemiometry = makeActionCreator(
    SemiometryTypes.FETCH_SEMIOMETRY,
);

const fetchSemiometryFailed = makeActionCreator(
    SemiometryTypes.FETCH_SEMIOMETRY_FAILED,
    {
        error:       null,
        description: null,
    },
);

const fetchSemiometrySucceeded = makeActionCreator(
    SemiometryTypes.FETCH_SEMIOMETRY_SUCCESS,
    {
        semiometries: null,
    },
);

const saveSemiometryAnswer = makeActionCreator(
    SemiometryTypes.SAVE_SEMIOMETRY_ANSWER,
    {
        semiometry: null,
        value:      null,
    },
);

const postSemiometryData = makeActionCreator(
    SemiometryTypes.POST_SEMIOMETRY_DATA,
);

const postSemiometryDataFailed = makeActionCreator(
    SemiometryTypes.POST_SEMIOMETRY_DATA_FAILED,
    {
        error:       null,
        description: null,
    },
);

const postSemiometryDataSucceeded = makeActionCreator(
    SemiometryTypes.POST_SEMIOMETRY_DATA_SUCCESS,
);

const markSemiometryDataAsPosted = makeActionCreator(
    SemiometryTypes.MARK_SEMIOMETRY_DATA_AS_POSTED,
    {
        semiometry: null,
    },
);

export const SemiometryActions = {
    fetchSemiometry,
    fetchSemiometryFailed,
    fetchSemiometrySucceeded,
    saveSemiometryAnswer,
    postSemiometryData,
    postSemiometryDataFailed,
    postSemiometryDataSucceeded,
    markSemiometryDataAsPosted,
};
