import classNames             from 'classnames';
import Icon                   from '../../components/Icon';
import Link                   from '../Link';
import PropTypes              from '../PropTypes';
import React                  from 'react';
import styles                 from './styles.module.scss';
import Title                  from '../../components/Title';
import TitleTheme             from '../Title/TitleTheme';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { WorkshopActions }    from '../../store/actions/activeWorkshop';
import AnswerTypes            from '../../constants/AnswerTypes';

class DashboardItem extends React.Component {
    getTheme = () => {
        return classNames(
            styles.dashboardItem,
            {
                [styles.dashboardItemActive]:   this.props.active,
                [styles.dashboardItemDisabled]: this.props.disabled,
            },
        );
    };

    dashboardItemPressed = () => {
        this.props.cloneQuestions({
            answerType: this.props.answerType,
        });
    };

    render () {
        return (
            <Link
                className={this.getTheme()}
                disabled={this.props.disabled}
                to={this.props.route}
                onClick={this.dashboardItemPressed}
            >
                <Icon
                    icon={this.props.icon}
                />
                <Title
                    text={this.props.title}
                    theme={TitleTheme.card}
                    uppercase={true}
                />
                <span>
                    {this.props.text}
                </span>
            </Link>
        );
    }
}

const Component = DashboardItem;

Component.propTypes = {
    active:     PropTypes.bool,
    answerType: PropTypes.oneOf(Object.values(AnswerTypes)),
    disabled:   PropTypes.bool,
    icon:       PropTypes.string,
    route:      PropTypes.string,
    text:       PropTypes.string,
    title:      PropTypes.string,
};

Component.defaultProps = {
    active:     false,
    answerType: '',
    disabled:   false,
    icon:       '',
    route:      '',
    text:       '',
    title:      '',
};

const mapDispatchToProps = dispatch => bindActionCreators(WorkshopActions, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(Component);