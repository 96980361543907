import _          from 'lodash';
import classNames from 'classnames';
import PropTypes  from '../PropTypes';
import React      from 'react';
import styles     from './styles.module.scss';

class MultipleChoiceSelectorChoice extends React.Component {
    getTheme = () => {
        return classNames(
            styles.multipleChoiceSelectorChoice,
            {
                [styles.multipleChoiceSelectorChoiceActive]: this.props.active,
            },
        );
    };

    render () {
        const word = this.props.attribute.word.replace('/', ' / ');

        return (
            <button
                className={this.getTheme()}
                onClick={this.props.onClick}
                disabled={this.props.disabled}
            >
                {word}
            </button>
        );
    }
}

const Component = MultipleChoiceSelectorChoice;

Component.propTypes = {
    active:    PropTypes.bool,
    attribute: PropTypes.object,
    disabled:  PropTypes.bool,
    onClick:   PropTypes.func,
};

Component.defaultProps = {
    active:    false,
    attribute: {},
    disabled:  false,
    onClick:   _.noop,
};

export default Component;