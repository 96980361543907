export default {
    arrow:    'arrow',
    calendar: 'calendar',
    check:    'check',
    company:  'company',
    cross:    'cross',
    envelope: 'envelope',
    eye:      'eye',
    hearth:   'hearth',
    play:     'play',
    rocket:   'rocket',
    spinner:  'spinner',
    user:     'user',
    users:    'users',
    write:    'write',
};
