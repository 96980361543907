export default class Api {
    static getBackendUrl() {
        const url = window.location.href;

        if (url.indexOf('.test') > -1 || url.indexOf('localhost') > -1) {
            return LOCAL_BACKEND_URL;
        } else if (url.indexOf('staging.') > -1) {
            return STAGING_BACKEND_URL;
        }

        return PRODUCTION_BACKEND_URL;
    };

    /**
     * @returns {boolean}
     */
    static isStaging() {
        return (
            BACKEND_URL === STAGING_BACKEND_URL
        );
    }
}

export const PRODUCTION_BACKEND_URL         = 'https://api.neuroplayce.de';
export const STAGING_BACKEND_URL            = 'https://api.staging.neuroplayce.de';
export const LOCAL_BACKEND_URL              = 'https://backend.neuroplayce.test';
export const BACKEND_URL                    = Api.getBackendUrl();
export const API_MOTIVATION_URL             = 'api/motivation-categories';
export const API_PARTICIPANT_MOTIVATION_URL = 'api/participant-motivations';
export const API_PARTICIPANT_SEMIOMETRY_URL = 'api/participant-semiometries';
export const API_PARTICIPANT_URL            = 'api/participants';
export const API_PARTICIPANT_ACTION_LOG_URL = 'api/participant-action-logs';
export const API_SEMIOMETRY_URL             = 'api/semiometry-categories';
export const API_WORKSHOP_FINISHED_URL      = 'api/workshops/finished';
export const API_WORKSHOP_URL               = 'api/workshops';

if (BACKEND_URL.endsWith('/')) {
    console.error('⚠️⚠️⚠️ Warning: Backend url should not end with a /');
}

if (BACKEND_URL !== PRODUCTION_BACKEND_URL) {
    console.warn('⚠️⚠️⚠️ Warning: App is not in production mode! ⚠️⚠️⚠️');
}

if (BACKEND_URL.indexOf('https://') === -1) {
    console.error('⚠️⚠️⚠️ Warning: Backend url is not secure ⚠️⚠️⚠️');
}
