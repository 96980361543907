import _                from 'lodash';
import ColorButton      from '../ColorButton';
import ColorButtonTheme from '../ColorButton/ColorButtonTheme';
import ComponentHelper  from '../../helper/ComponentHelper';
import IconType         from '../Icon/IconType';
import PropTypes        from '../PropTypes';
import React            from 'react';
import { connect }      from 'react-redux';

class LoadingIndicatorColorButton extends React.Component {
    render () {
        return (
            <ColorButton
                disabled={this.props.disabled}
                onClick={this.props.onClick}
                shadow={this.props.shadow}
                theme={this.props.theme}
                text={this.props.text}
                icon={this.props.loading ? IconType.spinner : null}
                iconIsRotating={this.props.loading}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LoadingIndicatorColorButton;

Component.propTypes = {
    disabled: PropTypes.bool,
    hidden:   PropTypes.bool,
    loading:  PropTypes.bool,
    onClick:  PropTypes.func,
    shadow:   PropTypes.bool,
    text:     PropTypes.string,
    theme:    PropTypes.oneOf(Object.values(ColorButtonTheme)),
};

Component.defaultProps = {
    disabled: false,
    hidden:   false,
    loading:  false,
    onClick:  _.noop,
    shadow:   false,
    text:     '',
    theme:    ColorButtonTheme.none,
};

Component.renderAffectingProps = [
    'disabled',
    'hidden',
    'loading',
    'text',
];

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        loading: _.get(state, 'activeWorkshop.activeWorkshop.isPosting', false),
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);