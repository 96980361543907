import React           from 'react';
import _               from 'lodash';
import Api             from '../../constants/Api';
import ColorButton     from '../ColorButton';
import ComponentHelper from '../../helper/ComponentHelper';
import I18n            from 'i18next';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import PropTypes       from '../PropTypes';
import ReactPlayer     from 'react-player';
import styles          from './styles.module.scss';

class VideoOverlay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            playing:    false,
            videoEnded: false,
        };
    }

    finishVideo = () => {
        this.props.videoEnded();
    };

    getType = () => {
        switch (this.props.answeringType) {
            // @formatter:off
            case 'semiometryAnswers':        return I18n.t('values');
            case 'currentMotivationAnswers': return I18n.t('motives');
            // @formatter:on
        }
    };

    getVideo = () => {
        const backendApiUrl = Api.getBackendUrl();

        switch (this.props.answeringType) {
            // @formatter:off
            case 'semiometryAnswers':        return [
                {
                    src:  `${backendApiUrl}/frontend/values-tutorial-video.webm`,
                    type: 'video/webm'
                },
                {
                    src:  `${backendApiUrl}/frontend/values-tutorial-video.mp4`,
                    type: 'video/mp4'
                }
            ];
            case 'currentMotivationAnswers': return [
                {
                    src:  `${backendApiUrl}/frontend/motives-tutorial-video.webm`,
                    type: 'video/webm'
                },
                {
                    src:  `${backendApiUrl}/frontend/motives-tutorial-video.mp4`,
                    type: 'video/mp4'
                }
            ];
            // @formatter:on
        }
    };

    getThumbnail = () => {
        switch (this.props.answeringType) {
            // @formatter:off
            case 'semiometryAnswers':        return require('../../assets/videos/thumbnails/values-tutorial-video.png');
            case 'currentMotivationAnswers': return require('../../assets/videos/thumbnails/motives-tutorial-video.png');
            // @formatter:on
        }
    };

    onClick = (event) => {
        event.preventDefault();
    };

    isReady = () => {
        this.setState({
            playing: true,
        });
    };

    playVideo = () => {
        this.setState({
            playing: true,
        });
    };

    onEnded = () => {
        this.setState({
            videoEnded: true,
        });
    };

    render() {
        return (
            <>
                <div className={styles.videoOverlayContainer}>
                    <div className={styles.videoOverlayContainerLeft}>
                        <h2>
                            {I18n.t(
                                'howToEvaluate',
                                {
                                    type: this.getType(),
                                },
                            )}
                        </h2>
                        <div className={styles.videoOverlayInnerContainer}>
                            <ReactPlayer
                                url={this.getVideo()}
                                light={this.getThumbnail()}
                                playing={true}
                                onEnded={this.onEnded}
                                width={'100%'}
                                height={'100%'}
                                controls={true}
                                onReady={this.isReady}
                                playIcon={this.renderIcon()}
                            />
                            {this.renderSkipButton()}
                        </div>
                    </div>
                </div>
                <div className={styles.multipleChoiceSelectorButtonContainer}>
                    {this.renderButton()}
                </div>
            </>
        );
    }

    renderIcon = () => {
        return (
            <Icon
                icon={IconType.play}
                onClick={this.playVideo}
            />
        );
    };

    renderButton = () => {
        return (
            <ColorButton
                text={I18n.t('more') + ' >'}
                disabled={!this.state.videoEnded}
                onClick={this.finishVideo}
                shadow={true}
            />
        );
    };

    renderSkipButton = () => {
        if (this.state.playing) {
            return (
                <div className={styles.videoOverlaySkipButtonContainer}>
                    <ColorButton
                        text={I18n.t('skip')}
                        onClick={this.finishVideo}
                    />
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = VideoOverlay;

Component.propTypes = {
    answeringType: PropTypes.string,
    videoEnded:    PropTypes.func,
};

Component.defaultProps = {
    answeringType: null,
    videoEnded:    _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'playing',
];

export default Component;
