import _ from 'lodash';

class StateHelper {
    static getCurrentUserAnswers (state, currentAnsweringType, currentAnswerIndex, fallback) {
        const path = ['userAnswers', currentAnsweringType, 'data', currentAnswerIndex];

        return _.get(state.activeWorkshop, _.reject(path, _.isNull), fallback);
    }
}

export default StateHelper;