import PropTypes from '../PropTypes';
import React     from 'react';
import styles    from './styles.module.scss';

class PointSelectorLabel extends React.Component {
    render () {
        const value = this.props.value;

        return (
            <div className={styles.multiplePointSelectorLabel}>
                <span className={styles.multiplePointSelectorLabelTitle}>
                    {this.props.title}
                </span>
                <span className={styles.multiplePointSelectorLabelValue}>
                    ({value > 0 ? '+' + value : value})
                </span>
            </div>
        );
    }
}

const Component = PointSelectorLabel;

Component.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
};

Component.defaultProps = {
    title: '',
    value: null,
};

export default Component;