import I18n      from 'i18next';
import Languages from '../constants/Languages';

class Language {
    /**
     * Creates a string using all passed values and used the word "and" to concat the last value.
     * So ['foo', '123', 'bar'] will become 'foo, 123 and bar'.
     *
     * @param values
     * @returns {string|*}
     */
    static andString (values) {
        return this.listString(
            values,
            ', ',
            I18n.t('and')
        );
    }

    static getDefaultLanguage () {
        const browserLanguage = navigator.language || navigator.userLanguage;
        const defaultLanguage = browserLanguage.startsWith('de') ? Languages.german : Languages.english;

        return defaultLanguage;
    }

    static listString (values, separator, endSeparator) {
        if (values.length > 1) {
            const stringData = [...values];
            const lastItem   = stringData.pop();
            const listString = [
                stringData.join(separator),
                endSeparator,
                lastItem
            ].join(' ');

            return listString;
        }

        return values[0];
    }

    /**
     * Creates a string using all passed values and used the word "or" to concat the last value.
     * So ['foo', '123', 'bar'] will become 'foo, 123 or bar'.
     *
     * @param values
     * @returns {string|*}
     */
    static orString (values) {
        return this.listString(
            values,
            ', ',
            I18n.t('or')
        );
    }
}

export default Language;