import React                  from 'react';
import _                      from 'lodash';
import classNames             from 'classnames';
import ComponentHelper        from '../helper/ComponentHelper';
import ContextQuestion        from '../components/ContextQuestion';
import Header                 from '../components/Header';
import I18n                   from 'i18next';
import MultiplePointSelector  from '../components/MultiplePointSelector';
import PageTitleHelper        from '../helper/PageTitle';
import StateHelper            from '../helper/State';
import styles                 from '../styles.module.scss';
import VideoOverlay           from '../components/VideoOverlay';
import { bindActionCreators } from 'redux';
import { Component }          from 'react';
import { connect }            from 'react-redux';
import { Helmet }             from 'react-helmet';
import { SemiometryActions }  from '../store/actions/semiometry';
import { WorkshopActions }    from '../store/actions/activeWorkshop';

class Screen extends Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Screen,
            nextProps,
            nextState,
        );
    }

    getProgress = () => {
        const completedCount = _.filter(this.props.semiometries, (attribute) => {
            return 'value' in attribute;
        }).length;

        return Math.round(completedCount / this.props.semiometries.length * 100);
    };

    motiveChanged = (value) => {
        const semiometry = {
            value: value,
        };

        this.props.saveSemiometryAnswer({
            semiometry: semiometry,
        });
    };

    render() {
        return (
            <div
                className={classNames(
                    styles.main,
                    styles.noFooter,
                )}
            >
                <Header />
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('values'))}
                    </title>
                </Helmet>
                <div className={classNames(styles.container, styles.notCentered)}>
                    <ContextQuestion />
                    {this.renderContent()}
                </div>
            </div>
        );
    }

    renderContent = () => {
        if (this.props.watchedVideo) {
            return (
                <MultiplePointSelector
                    attributes={this.props.semiometries}
                    currentIndex={this.props.currentAnswerIndex}
                    progress={this.getProgress()}
                    motiveChanged={this.motiveChanged}
                />
            );
        }

        return (
            <VideoOverlay
                answeringType={this.props.currentAnsweringType}
                videoEnded={this.videoEnded}
            />
        );
    };

    videoEnded = () => {
        this.props.videoFinished();
    };
}

Screen.renderAffectingProps = [
    'currentAnsweringType',
    'currentAnswerIndex',
    'semiometries',
    'watchedVideo',
];

Screen.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        currentAnsweringType: _.get(state.activeWorkshop, 'currentAnswerState.answeringType', null),
        currentAnswerIndex:   _.get(state.activeWorkshop, 'currentAnswerState.answerIndex', null),
        semiometries:         StateHelper.getCurrentUserAnswers(
            state,
            _.get(state.activeWorkshop, 'currentAnswerState.answeringType', null),
            null,
            [],
        ),
        watchedVideo:         _.get(
            state.activeWorkshop,
            [
                'userAnswers',
                _.get(state.activeWorkshop, 'currentAnswerState.answeringType', null),
                'watchedVideo',
            ],
            false,
        ),
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    Object.assign(
        SemiometryActions,
        WorkshopActions,
    ),
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
