import * as Api              from '../../api';
import _                     from 'lodash';
import I18n                  from 'i18next';
import { AlertBoxActions }   from '../actions/alertBox';
import { call }              from 'redux-saga/effects';
import { put }               from 'redux-saga/effects';
import { select }            from 'redux-saga/effects';
import { SemiometryActions } from '../actions/semiometry';
import { WorkshopActions }   from '../actions/activeWorkshop';

const fetchSemiometries = function* () {
    const response = yield call(Api.fetchSemiometries);

    if (response.ok) {
        const semiometries = response.data;

        yield put(SemiometryActions.fetchSemiometrySucceeded({
            semiometries,
        }));
    } else {
        yield put(SemiometryActions.fetchSemiometryFailed({
            error:       'error_semiometry',
            description: I18n.t('error_semiometry_api'),
        }));
    }
};

const fetchSemiometriesFailed = function* ({ error, description }) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(error) + ': ' + description,
    }));
};

const postBatch = function* (semiometries, participant, workshop, semiometryErrors) {
    const semiometry = semiometries.shift();

    if (
        semiometry &&
        !semiometry.sent
    ) {
        const response = yield call(
            Api.postSemiometry,
            participant.iri,
            workshop.iri,
            semiometry.iri,
            semiometry.value,
        );

        if (response.ok) {
            yield put(SemiometryActions.markSemiometryDataAsPosted({
                semiometry: semiometry,
            }));
        } else {
            semiometryErrors.push(semiometry);

            const description = response.data ? response.data['hydra:description'].replace('participant: ', '') : null;

            yield put(SemiometryActions.postSemiometryDataFailed({
                error:       'error_semiometry',
                description: description ? description : I18n.t('error_semiometry_api'),
            }));
        }
    }

    if (semiometries.length > 0) {
        yield postBatch(semiometries, participant, workshop, semiometryErrors);
    } else if (!semiometryErrors.length) {
        yield put(SemiometryActions.postSemiometryDataSucceeded());
    } else {
        yield put(WorkshopActions.postWorkshopDataFailed());
    }
};

const postSemiometries = function* () {
    const participant                 = yield select(state => state.activeWorkshop.activeWorkshop.participant);
    const workshop                    = yield select(state => state.activeWorkshop.activeWorkshop);
    const currentSemiometries         = yield select(state => state.activeWorkshop.userAnswers.semiometryAnswers);
    const filteredCurrentSemiometries = _.filter(currentSemiometries.data, { sent: false });

    yield postBatch(filteredCurrentSemiometries, participant, workshop, []);
};

const postSemiometriesFailed = function* ({ error, description }) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(error) + ': ' + description,
    }));
};

export default {
    fetchSemiometries,
    fetchSemiometriesFailed,
    postSemiometries,
    postSemiometriesFailed,
};
