import classNames      from 'classnames';
import ComponentHelper from '../../helper/ComponentHelper';
import Copyright       from '../Copyright';
import FooterTheme     from './FooterTheme';
import I18n            from 'i18next';
import PropTypes       from '../PropTypes';
import React           from 'react';
import Routes          from '../../constants/Routes';
import styles          from './styles.module.scss';
import { Link }        from 'react-router-dom';

class Footer extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div
                className={classNames(
                    styles.footer,
                    {
                        [styles.blueFooterLink]: this.props.theme === FooterTheme.blue,
                    },
                )}
            >
                <div className={styles.footerLinks}>
                    <Link
                        to={Routes.imprint}
                        className={styles.footerLink}
                    >
                        {I18n.t('imprint')}
                    </Link>
                    <Link
                        to={Routes.privacy}
                        className={classNames(
                            styles.footerLink,
                            {
                                [styles.blueFooterLink]: this.props.theme === FooterTheme.blue,
                            },
                        )}
                    >
                        {I18n.t('privacy')}
                    </Link>
                </div>
                <Copyright
                    theme={this.props.theme}
                />
            </div>
        );
    }
}

const Component = Footer;

Component.propTypes = {
    theme: PropTypes.oneOf(Object.values(FooterTheme)),
};

Component.defaultProps = {
    theme: FooterTheme.none,
};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;