import React       from 'react';
import _           from 'lodash';
import PropTypes   from '../PropTypes';
import { connect } from 'react-redux';

class WindowWatch extends React.Component {
    constructor(props) {
        super(props);

        this.onUnload = this.onUnload.bind(this);
    }

    // This would not work with an arrow function.
    onUnload = function (event) {
        if (!this.props.completelyFinished) {
            event.preventDefault();
            event.returnValue = '';
        }
    };

    componentDidMount() {
        window.addEventListener('beforeunload', this.onUnload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onUnload);
    }

    render() {
        return null;
    }
}

const Component = WindowWatch;

Component.propTypes = {
    completelyFinished: PropTypes.bool,
};

Component.defaultProps = {
    completelyFinished: false,
};

const mapStateToProps = state => (
    {
        completelyFinished: _.get(state.activeWorkshop, 'activeWorkshop.isFinished', false),
    }
);

export default connect(
    mapStateToProps,
    null,
)(WindowWatch);
