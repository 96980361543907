import { makeActionCreator } from '../../helper/Store';

export const ParticipantTypes = {
    CREATE_PARTICIPANT:         'Participant/CREATE_PARTICIPANT',
    CREATE_PARTICIPANT_FAILED:  'Participant/CREATE_PARTICIPANT_FAILED',
    CREATE_PARTICIPANT_SUCCESS: 'Participant/CREATE_PARTICIPANT_SUCCESS',
};

const createParticipant = makeActionCreator(
    ParticipantTypes.CREATE_PARTICIPANT,
    {
        name:       null,
        email:      null,
        department: null,
    },
);

const createParticipantFailed = makeActionCreator(
    ParticipantTypes.CREATE_PARTICIPANT_FAILED,
    {
        error:       null,
        description: null,
    },
);

const createParticipantSucceeded = makeActionCreator(
    ParticipantTypes.CREATE_PARTICIPANT_SUCCESS,
    {
        participant: null,
    },
);

export const ParticipantActions = {
    createParticipant,
    createParticipantFailed,
    createParticipantSucceeded,
};
