import * as Api              from '../../api';
import _                     from 'lodash';
import AnswerTypes           from '../../constants/AnswerTypes';
import I18n                  from 'i18next';
import Routes                from '../../constants/Routes';
import { AlertBoxActions }   from '../actions/alertBox';
import { all }               from 'redux-saga/effects';
import { call }              from 'redux-saga/effects';
import { put }               from 'redux-saga/effects';
import { race }              from 'redux-saga/effects';
import { select }            from 'redux-saga/effects';
import { take }              from 'redux-saga/effects';
import { MotivationActions } from '../actions/motivation';
import { MotivationTypes }   from '../actions/motivation';
import { push }              from 'connected-react-router';
import { SemiometryActions } from '../actions/semiometry';
import { SemiometryTypes }   from '../actions/semiometry';
import { WorkshopActions }   from '../actions/activeWorkshop';
import * as queryString      from 'query-string';

const getWorkshop = function* () {
    const activeWorkshop     = yield select((state) => state.activeWorkshop.activeWorkshop);
    const location           = yield select((state) => state.router.location);
    const urlParameterString = _.get(location, 'search', '');
    const path               = _.get(location, 'pathname', '');
    const urlParameter       = queryString.parse(urlParameterString);

    if (path === Routes.login && !activeWorkshop.iri) {
        if (urlParameter.workshop) {
            yield put(WorkshopActions.fetchWorkshop({
                id: urlParameter.workshop,
            }));
        } else {
            yield put(WorkshopActions.fetchWorkshops());
        }
    }
};

const fetchWorkshop = function* ({ id }) {
    const response = yield call(Api.fetchWorkshop, id);

    if (response.ok) {
        const activeWorkshop = response.data;

        yield put(WorkshopActions.fetchWorkshopSucceeded({
            activeWorkshop,
        }));

        yield put(MotivationActions.fetchMotivation());
        yield put(SemiometryActions.fetchSemiometry());
    } else {
        yield put(WorkshopActions.fetchWorkshopFailed({
            error:       'error_workshop',
            description: response.data['hydra:description'],
        }));
    }
};

const fetchWorkshopFailed = function* ({ error, description }) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(error) + ': ' + description,
    }));
};

const fetchWorkshops = function* () {
    const response      = yield call(Api.fetchWorkshops);
    const workshops     = response.data;
    const workshopCount = _.get(workshops, 'member.length', 0);

    if (response.ok && workshopCount === 1) {
        const workshop = _.get(workshops, 'member.0');

        yield put(WorkshopActions.fetchWorkshopsSucceeded({
            activeWorkshop: workshop,
        }));

        yield put(MotivationActions.fetchMotivation());
        yield put(SemiometryActions.fetchSemiometry());
    } else if (workshopCount > 1) {
        yield put(WorkshopActions.fetchWorkshopsFailed({
            error:       'error_workshops',
            description: I18n.t('error_multiple_workshop'),
        }));
    } else if (!workshopCount) {
        yield put(WorkshopActions.fetchWorkshopsFailed({
            error:       'error_workshops',
            description: I18n.t('error_no_active_workshop'),
        }));
    } else {
        yield put(WorkshopActions.fetchWorkshopFailed({
            error:       'error_workshop',
            description: response.data['hydra:description'],
        }));
    }
};

const fetchWorkshopsFailed = function* ({ error, description }) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(error) + ': ' + description,
    }));
};

const cloneQuestions = function* ({ answerType }) {
    if (
        answerType === AnswerTypes.currentMotivationAnswers ||
        answerType === AnswerTypes.futureMotivationAnswers
    ) {
        const motivations = yield select(state => state.motivation.motivations);

        yield put(WorkshopActions.startAnswering({
            answerType,
            motivations,
        }));
    } else if (answerType === AnswerTypes.semiometryAnswers) {
        const semiometries = yield select(state => state.semiometry.semiometries);

        yield put(WorkshopActions.startAnswering({
            answerType,
            semiometries,
        }));
    } else {
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('error_clone_question'),
        }));
    }
};

const finishAnswering = function* () {
    yield put(push(Routes.home));
};

const postWorkshopData = function* () {
    function* postSemiometry() {
        yield put(SemiometryActions.postSemiometryData());

        return yield race({
            failure: take(SemiometryTypes.POST_SEMIOMETRY_DATA_FAILED),
            success: take(SemiometryTypes.POST_SEMIOMETRY_DATA_SUCCESS),
        });
    }

    function* postMotivation() {
        yield put(MotivationActions.postMotivationData());

        return yield race({
            failure: take(MotivationTypes.POST_MOTIVATION_DATA_FAILED),
            success: take(MotivationTypes.POST_MOTIVATION_DATA_SUCCESS),
        });
    }

    const { semiometryResult, motivationResult } = yield all({
        semiometryResult: call(postSemiometry),
        motivationResult: call(postMotivation),
    });

    if (
        'success' in semiometryResult &&
        'success' in motivationResult
    ) {
        yield put(WorkshopActions.postWorkshopDataSucceeded());
    } else if (
        'failure' in semiometryResult &&
        'failure' in motivationResult
    ) {
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('error_server_offline'),
        }));
    }
};

const postWorkshopDataSucceeded = function* () {
    const activeWorkshop = yield select((state) => state.activeWorkshop.activeWorkshop);
    const participant    = yield select((state) => state.activeWorkshop.activeWorkshop.participant);

    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('success_data_posted'),
    }));

    yield call(
        Api.postWorkshopFinished,
        participant.iri,
        activeWorkshop.iri,
    );
};

export default {
    getWorkshop,
    fetchWorkshop,
    fetchWorkshopFailed,
    fetchWorkshops,
    fetchWorkshopsFailed,
    cloneQuestions,
    finishAnswering,
    postWorkshopData,
    postWorkshopDataSucceeded,
};
