import _                   from 'lodash';
import classNames          from 'classnames';
import ComponentHelper     from '../../helper/ComponentHelper';
import PointSelector       from '../PointSelector';
import pointSelectorLabels from '../../constants/PointSelectors';
import PropTypes           from '../PropTypes';
import React               from 'react';
import styles              from './styles.module.scss';

class PointSelectorRow extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    getBackgroundTheme = () => {
        return classNames(
            styles.pointSelectorRowBackground,
            {
                [styles.pointSelectorRowBackgroundActive]: this.props.active,
            },
        );
    };

    getBulletTheme = () => {
        return classNames(
            styles.pointSelectorRowButtons,
            {
                [styles.pointSelectorRowButtonsActive]: this.props.active,
            },
        );
    };

    getBulletId = () => {
        if (this.props.active) {
            return `values-active-row`;
        }

        return null;
    };

    getTheme = () => {
        return classNames(
            styles.pointSelectorRow,
            {
                [styles.pointSelectorRowActive]: this.props.active,
            },
        );
    };

    render() {
        return (
            <div className={this.getTheme()}>
                <div className={this.getBackgroundTheme()} />
                <div className={styles.pointSelectorRowContent}>
                    <div className={styles.pointSelectorRowTitle}>
                        {this.props.attribute.word}
                    </div>
                    <div
                        id={this.getBulletId()}
                        className={this.getBulletTheme()}
                    >
                        {pointSelectorLabels.map(this.renderPointSelector)}
                    </div>
                </div>
            </div>
        );
    }

    renderPointSelector = (item, index) => {
        const labelIndex = pointSelectorLabels[index].value;

        return (
            <PointSelector
                key={'pointSelectorRowSelector-' + index}
                value={item.value}
                active={this.props.attribute.value === item.value}
                disabled={typeof this.props.attribute.value !== 'undefined'}
                onClick={() => {
                    this.props.onClick(labelIndex);
                }}
            />
        );
    };
}

const Component = PointSelectorRow;

Component.propTypes = {
    active:    PropTypes.bool,
    attribute: PropTypes.object,
    onClick:   PropTypes.func,
};

Component.defaultProps = {
    active:    false,
    attribute: {
        word: '',
    },
    onClick:   _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
