import { makeActionCreator } from '../../helper/Store';

export const NavigationTypes = {
    RESET_STATE: 'Navigation/RESET_STATE',
};

const resetState = makeActionCreator(
    NavigationTypes.RESET_STATE,
);

export const NavigationActions = {
    resetState,
};