class Cast {
    static bool (value) {
        const castedValue = !!value;

        return castedValue;
    }

    static int (value) {
        const castedValue = parseInt(value, 10) || 0;

        return castedValue;
    }
}

export default Cast;