import _               from 'lodash';
import ComponentHelper from '../../helper/ComponentHelper';
import HeaderItem      from '../../components/HeaderItem';
import I18n            from 'i18next';
import IconType        from '../Icon/IconType';
import Logo            from '../../assets/images/logo.svg';
import moment          from 'moment';
import PropTypes       from '../PropTypes';
import React           from 'react';
import Routes          from '../../constants/Routes';
import styles          from './styles.module.scss';
import { connect }     from 'react-redux';
import { Link }        from 'react-router-dom';

class Header extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render() {
        const person = this.getPerson();

        return (
            <div className={styles.header}>
                <Link
                    to={Routes.home}
                    className={styles.headerLogo}
                >
                    <Logo />
                </Link>
                <div className={styles.headerNavigationContent}>
                    <HeaderItem
                        text={this.getWorkshopTitle()}
                        icon={IconType.write}
                    />
                    <HeaderItem
                        text={this.getDate()}
                        icon={IconType.calendar}
                    />
                    {
                        person ? (
                            <HeaderItem
                                text={person}
                                icon={IconType.user}
                            />
                        ) : null
                    }
                </div>
            </div>
        );
    }

    getDate = () => {
        const date = _.get(this.props.activeWorkshop, 'date', moment());

        return moment(date).format(I18n.t('dateFormat'));
    };

    getPerson = () => {
        let name  = _.get(this.props.activeWorkshop, 'participant.name', '');
        const email = _.get(this.props.activeWorkshop, 'participant.email', '');

        if (email) {
            name = `${name} (${email})`;
        }

        return _.compact([name, this.props.departmentName]).join(', ');
    };

    getWorkshopTitle = () => {
        const workshopTitle = _.get(this.props.activeWorkshop, 'title', '');

        if (!workshopTitle) {
            const customerTitle = _.get(this.props.activeWorkshop, 'customer.title', '');

            return customerTitle;
        }

        return workshopTitle;
    };
}

const Component = Header;

Component.propTypes = {
    activeWorkshop: PropTypes.object,
    departmentName: PropTypes.string,
};

Component.defaultProps = {
    activeWorkshop: {},
    departmentName: '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => {
    const activeWorkshop        = _.get(state.activeWorkshop, 'activeWorkshop', {});
    const participantDepartment = _.get(activeWorkshop, 'participant.department', null);
    const departmentName        = _.get(
        _.find(
            _.get(
                activeWorkshop,
                'departments',
            ),
            {
                iri: participantDepartment,
            },
        ),
        'title',
    );

    return {
        activeWorkshop,
        departmentName,
    };
};

export default connect(
    mapStateToProps,
)(Component);
