import ComponentHelper from '../../helper/ComponentHelper';
import PropTypes       from '../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class Paragraph extends React.Component {
    render () {
        return (
            <p className={styles.paragraph}>
                {this.props.children}
            </p>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Paragraph;

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;