import Routes                from '../../constants/Routes';
import { AlertBoxActions }   from '../actions/alertBox';
import { NavigationActions } from '../actions/navigation';
import { push }              from 'connected-react-router';
import { put }               from 'redux-saga/effects';
import { select }            from 'redux-saga/effects';
import { WorkshopActions }   from '../actions/activeWorkshop';

const locationChange = function* () {
    const participant = yield select(state => state.activeWorkshop.activeWorkshop.participant);
    const location    = yield select(state => state.router.location);
    const route       = location.pathname;

    if (route === Routes.login) {
        yield put(NavigationActions.resetState());

        const urlParams  = new URLSearchParams(location.search);
        const workshopId = urlParams.get('workshop');

        if (!workshopId) {
            yield put(WorkshopActions.fetchWorkshops());
        } else {
            yield put(WorkshopActions.fetchWorkshop({ id: workshopId }));
        }

    } else if (!participant || !participant.iri) {
        yield put(push(Routes.login));
    }

    yield put(AlertBoxActions.clearAlerts());
};

export default {
    locationChange,
};
