import { makeActionCreator } from '../../helper/Store';

export const MotivationTypes = {
    FETCH_MOTIVATION:               'Motivation/FETCH_MOTIVATION',
    FETCH_MOTIVATION_FAILED:        'Motivation/FETCH_MOTIVATION_FAILED',
    FETCH_MOTIVATION_SUCCESS:       'Motivation/FETCH_MOTIVATION_SUCCESS',
    SAVE_MOTIVATION_ANSWER:         'Motivation/SAVE_MOTIVATION_ANSWER',
    POST_MOTIVATION_DATA:           'Motivation/POST_MOTIVATION_DATA',
    POST_MOTIVATION_DATA_FAILED:    'Motivation/POST_MOTIVATION_DATA_FAILED',
    POST_MOTIVATION_DATA_SUCCESS:   'Motivation/POST_MOTIVATION_DATA_SUCCESS',
    MARK_MOTIVATION_DATA_AS_POSTED: 'Motivation/MARK_MOTIVATION_DATA_AS_POSTED',
};

const fetchMotivation = makeActionCreator(
    MotivationTypes.FETCH_MOTIVATION,
);

const fetchMotivationFailed = makeActionCreator(
    MotivationTypes.FETCH_MOTIVATION_FAILED,
    {
        error:       null,
        description: null,
    },
);

const fetchMotivationSucceeded = makeActionCreator(
    MotivationTypes.FETCH_MOTIVATION_SUCCESS,
    {
        motivations: null,
    },
);

const saveMotivationAnswer = makeActionCreator(
    MotivationTypes.SAVE_MOTIVATION_ANSWER,
    {
        index:      null,
        motivation: null,
        selected:   null,
    },
);

const postMotivationData = makeActionCreator(
    MotivationTypes.POST_MOTIVATION_DATA,
);

const postMotivationDataFailed = makeActionCreator(
    MotivationTypes.POST_MOTIVATION_DATA_FAILED,
    {
        error:       null,
        description: null,
    },
);

const postMotivationDataSucceeded = makeActionCreator(
    MotivationTypes.POST_MOTIVATION_DATA_SUCCESS,
);

const markMotivationDataAsPosted = makeActionCreator(
    MotivationTypes.MARK_MOTIVATION_DATA_AS_POSTED,
    {
        motivation: null,
        answerType: null,
    },
);

export const MotivationActions = {
    fetchMotivation,
    fetchMotivationFailed,
    fetchMotivationSucceeded,
    saveMotivationAnswer,
    postMotivationData,
    postMotivationDataFailed,
    postMotivationDataSucceeded,
    markMotivationDataAsPosted,
};