import ComponentHelper from '../../helper/ComponentHelper';
import HeadlineTheme   from './HeadlineTheme';
import PropTypes       from '../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import classNames      from 'classnames';

class Headline extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div className={styles.headlineWrapper}>
                <div
                    className={classNames(
                        styles.headline,
                        {
                            [styles.headlineLarge]: this.props.theme === HeadlineTheme.large,
                        },
                    )}
                >
                    {this.props.text}
                </div>
            </div>
        );
    }
}

const Component = Headline;

Component.propTypes = {
    text:  PropTypes.string,
    theme: PropTypes.oneOf(Object.values(HeadlineTheme)),
};

Component.defaultProps = {
    text:  '',
    theme: HeadlineTheme.none,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;