import classNames      from 'classnames';
import FooterTheme     from '../Footer/FooterTheme';
import I18n            from 'i18next';
import PropTypes       from '../PropTypes';
import React           from 'react';
import SelectionHelper from '../../helper/SelectionHelper';
import styles          from './styles.module.scss';

class Copyright extends React.Component {
    render () {
        return (
            <div
                className={
                    classNames(
                        styles.copyright,
                        SelectionHelper.get(
                            this.props.theme,
                            {
                                [FooterTheme.blue]: styles.blueCopyright,
                            },
                        ),
                    )}
            >
                {I18n.t('copyright')}
            </div>
        );
    }
}

const Component = Copyright;

Component.propTypes = {
    theme: PropTypes.oneOf(Object.values(FooterTheme)),
};

Component.defaultProps = {
    theme: FooterTheme.none,
};

export default Component;