import { makeActionCreator } from '../../helper/Store';

export const DebugTypes = {
    REDUCE_QUESTIONS:  'Debug/REDUCE_QUESTIONS',
    RESTORE_QUESTIONS: 'Debug/RESTORE_QUESTIONS',
};

const reduceQuestions = makeActionCreator(
    DebugTypes.REDUCE_QUESTIONS,
);

const restoreQuestions = makeActionCreator(
    DebugTypes.RESTORE_QUESTIONS,
);

export const DebugActions = {
    reduceQuestions,
    restoreQuestions,
};