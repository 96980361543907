const german = {
    appropriate:                           'passend',
    back:                                  'Zurück',
    backToLogin:                           'zur Anmeldung',
    company:                               'Firma',
    copyright:                             '© vergissmeinnicht',
    dateFormat:                            'DD.MM.YYYY',
    department:                            'Abteilung',
    eitherAppropriateOrInappropriate:      'weder passend noch unpassend',
    emailOptional:                         'E-Mail (optional)',
    error:                                 'Fehler',
    error_404:                             '404',
    error_clone_question:                  'Fragen konnten nicht geladen werden.',
    error_motivation:                      'Werte',
    error_motivation_api:                  'Fehler beim Laden der Motivations-Daten.',
    error_motivation_send_failed:          'Die Werte konnten nicht an den Server übertragen werden.',
    error_multiple_workshop:               'Mehrere aktive Workshops gefunden.',
    error_no_active_workshop:              'Keine aktiven Workshops gefunden.',
    error_page_not_found:                  'Die Seite konnte nicht gefunden werden.',
    error_participant:                     'Teilnehmer',
    error_participant_already_exists:      'Der Teilnehmer ist bereits angelegt und kann nicht weiter bearbeitet werden.\n\nWenn Sie Neuroplayce noch nicht vollständig ausgefüllt haben, dann möchten wir Sie bitten, sich noch einmal neu anzulegen - z.B. Max Mustermann > Neu: M. Mustermann',
    error_semiometry:                      'Motive',
    error_semiometry_api:                  'Fehler beim Laden der Semiometrie-Daten.',
    error_semiometry_send_failed:          'Die Motive konnten nicht an den Server übertragen werden.',
    error_server_offline:                  'Daten konnten nicht gesendet werden. Server offline?',
    error_workshop:                        'Workshop',
    error_workshops:                       'Workshops',
    fallbackContextQuestion:               'Wie ist die Wahrnehmung der Firma {{companyName}}',
    finish:                                'Beenden',
    home:                                  'Home',
    howToEvaluate:                         'Wie bewerte ich die {{type}}?',
    imprint:                               'Impressum',
    inappropriate:                         'unpassend',
    inFuture:                              'Zukunft',
    inventory:                             'Inventar',
    inventoryDetails:                      'Inventar-Details',
    itemNotFound:                          'Eintrag nicht gefunden',
    login:                                 'Login',
    more:                                  'Mehr',
    motive:                                'Motiv',
    motives:                               'Motive',
    name:                                  'Name',
    neuroplayce:                           'Neuroplayce',
    no:                                    'Nein',
    privacy:                               'Datenschutz',
    ratherAppropriate:                     'eher passend',
    ratherInappropriate:                   'eher unpassend',
    reloadData:                            'Daten neu laden',
    send:                                  'absenden',
    skip:                                  'Überspringen',
    skipAnswer:                            'Nichts trifft zu',
    statusQuo:                             'Gegenwart',
    success:                               'Erfolg',
    success_data_posted:                   'Die Daten wurden erfolgreich an den Server gesendet.',
    uploadingData:                         'Lade Daten hoch',
    values:                                'Werte',
    veryAppropriate:                       'sehr passend',
    veryInappropriate:                     'sehr unpassend',
    windowSize:                            'Fenstergröße',
    yes:                                   'Ja',
    'Workshop/START_ANSWERING':            'Hat begonnen Antworten auszufüllen.',
    'Workshop/FINISH_ANSWERING':           'Hat das ausfüllen beendet.',
    'Workshop/VIDEO_FINISHED':             'Hat das Video gesehen.',
    'Workshop/POST_WORKSHOP_DATA':         'Hat den Workshop abgeschlossen',
    'Workshop/POST_WORKSHOP_DATA_FAILED':  'Abschließen des Workshops ist fehlgeschlagen.',
    'Workshop/POST_WORKSHOP_DATA_SUCCESS': 'Abschließen des Workshops war erfolgreich.',
    //
    // The following entries are mandatory
    //
    and:       'und',
    oclock:    'Uhr',
    or:        'oder',
    pageTitle: 'Neuroplayce',
    //
    // Non final
    //
};

module.exports = german;
