import AlertBoxManager from '../components/AlertBoxManager';
import ComponentHelper from '../helper/ComponentHelper';
import Footer          from '../components/Footer';
import FooterTheme     from '../components/Footer/FooterTheme';
import Header          from '../components/Header';
import Headline        from '../components/Headline';
import I18n            from 'i18next';
import PageTitleHelper from '../helper/PageTitle';
import Paragraph       from '../components/Paragraph';
import React           from 'react';
import styles          from '../styles.module.scss';
import { Component }   from 'react';
import { Helmet }      from 'react-helmet';
import HeadlineTheme   from '../components/Headline/HeadlineTheme';

class Screen extends Component {
    shouldComponentUpdate (nextProps, nextState, nextContext) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Screen,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div className={styles.main}>
                <Header />
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('home'))}
                    </title>
                </Helmet>
                <AlertBoxManager />

                <div className={styles.textContainer}>
                    <Headline
                        theme={HeadlineTheme.large}
                        text={'Datenschutzerklärung'}
                    />
                    <Headline
                        text={'1. Datenschutz auf einen Blick'}
                    />
                    <Paragraph>
                        Allgemeine Hinweise
                        <br />
                        <br />
                        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn
                        Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden
                        können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
                        Datenschutzerklärung.
                        <br />
                        <br />
                        <br />
                        Datenerfassung auf dieser Website
                        <br />
                        <br />
                        <br />
                        Wer ist verantwortlich für die Datenerfassung auf dieser Website?
                        <br />
                        <br />
                        Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem
                        Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
                        <br />
                        <br />
                        <br />
                        Wie erfassen wir Ihre Daten?
                        <br />
                        <br />
                        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten
                        handeln, die Sie in ein Kontaktformular eingeben.
                        <br />
                        <br />
                        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme
                        erfasst.
                        Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                        Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
                        <br />
                        <br />
                        <br />
                        Wofür nutzen wir Ihre Daten?
                        <br />
                        <br />
                        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten
                        können
                        zur Analyse Ihres Nutzerverhaltens verwendet werden.
                        <br />
                        <br />
                        <br />
                        Welche Rechte haben Sie bezüglich Ihrer Daten?
                        <br />
                        <br />
                        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten
                        personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu
                        verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
                        für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der
                        Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der
                        zuständigen Aufsichtsbehörde zu.
                        <br />
                        <br />
                        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
                        <br />
                    </Paragraph>
                    <Headline
                        text={'2. Hosting und Content Delivery Networks (CDN)'}
                    />
                    <Paragraph>
                        Externes Hosting
                        <br />
                        <br />
                        Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf dieser
                        Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen,
                        Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
                        Daten, die über eine Website generiert werden, handeln.
                        <br />
                        <br />
                        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden
                        Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung
                        unseres
                        Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
                        <br />
                        <br />
                        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich
                        ist und unsere Weisungen in Bezug auf diese Daten befolgen.
                        <br />
                        <br />
                        Wir setzen folgenden Hoster ein:
                        <br />
                        <br />
                        Amazon AWS
                        <br />
                    </Paragraph>
                    <Headline
                        text={'3. Allgemeine Hinweise und Pflichtinformationen'}
                    />
                    <Paragraph>
                        Datenschutz
                        <br />
                        <br />
                        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                        personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
                        Datenschutzerklärung.
                        <br />
                        <br />
                        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind
                        Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche
                        Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
                        <br />
                        <br />
                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
                        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                        <br />
                        <br />
                        <br />
                        Hinweis zur verantwortlichen Stelle
                        <br />
                        <br />
                        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                        <br />
                        <br />
                        vergissmeinnicht GmbH
                        <br />
                        Münsterplatz 5
                        <br />
                        78462 Konstanz
                        <br />
                        <br />
                        Telefon: +49 7531 9225900
                        <br />
                        E-Mail: info@vergissmeinnicht-kommunikation.de
                        <br />
                        <br />
                        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die
                        Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
                        <br />
                        <br />
                        <br />
                        Speicherdauer
                        <br />
                        <br />
                        Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre
                        personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes
                        Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht,
                        sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z.B.
                        steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall
                        dieser Gründe.
                        <br />
                        <br />
                        <br />
                        Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
                        <br />
                        <br />
                        Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich
                        nicht sicheren Drittstaaten eingebunden. Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese
                        Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern kein mit der EU
                        vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet,
                        personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich
                        vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf
                        US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf
                        diese Verarbeitungstätigkeiten keinen Einfluss.
                        <br />
                        <br />
                        <br />
                        Widerruf Ihrer Einwilligung zur Datenverarbeitung
                        <br />
                        <br />
                        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits
                        erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
                        bleibt vom Widerruf unberührt.
                        <br />
                        <br />
                        <br />
                        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)
                        <br />
                        <br />
                        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS
                        RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN
                        DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
                        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
                        EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN
                        ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
                        ÜBERWIEGEN
                        ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH
                        ART. 21 ABS. 1 DSGVO).
                        <br />
                        <br />
                        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT
                        WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
                        EINZULEGEN;
                        DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN,
                        WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
                        NACH
                        ART. 21 ABS. 2 DSGVO).
                        <br />
                        <br />
                        <br />
                        Beschwerderecht bei der zuständigen Aufsichtsbehörde
                        <br />
                        <br />
                        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde,
                        insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des
                        mutmaßlichen
                        Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
                        Rechtsbehelfe.
                        <br />
                        <br />
                        <br />
                        Recht auf Datenübertragbarkeit
                        <br />
                        <br />
                        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert
                        verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern
                        Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
                        technisch machbar ist.
                        <br />
                        <br />
                        <br />
                        SSL- bzw. TLS-Verschlüsselung
                        <br />
                        <br />
                        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel
                        Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine
                        verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“
                        wechselt
                        und an dem Schloss-Symbol in Ihrer Browserzeile.
                        <br />
                        <br />
                        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von
                        Dritten
                        mitgelesen werden.
                        <br />
                        <br />
                        <br />
                        Auskunft, Löschung und Berichtigung
                        <br />
                        <br />
                        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über
                        Ihre
                        gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein
                        Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten
                        können Sie sich jederzeit an uns wenden.
                        <br />
                        <br />
                        <br />
                        Recht auf Einschränkung der Verarbeitung
                        <br />
                        <br />
                        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können
                        Sie
                        sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
                        <br />
                        <ul>
                            <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in
                                der
                                Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der
                                Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            </li>
                            <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der
                                Löschung die Einschränkung der Datenverarbeitung verlangen.
                            </li>
                            <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder
                                Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der
                                Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            </li>
                            <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und
                                unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie
                                das
                                Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            </li>
                        </ul>
                        <br />
                        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer
                        Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
                        Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen
                        eines
                        wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
                    </Paragraph>
                    <Headline
                        text={'4. Datenerfassung auf dieser Website'}
                    />
                    <Paragraph>
                        Server-Log-Dateien
                        <br />
                        <br />
                        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr
                        Browser automatisch an uns übermittelt. Dies sind:
                        <br />
                        <ul>
                            <li>Browsertyp und Browserversion</li>
                            <li>verwendetes Betriebssystem</li>
                            <li>Referrer URL</li>
                            <li>Hostname des zugreifenden Rechners</li>
                            <li>Uhrzeit der Serveranfrage</li>
                            <li>IP-Adresse</li>
                        </ul>
                        <br />
                        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
                        <br />
                        <br />
                        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                        berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen
                        die Server-Log-Files erfasst werden.
                        <br />
                        <br />
                        <br />
                        Registrierung auf dieser Website
                        <br />
                        <br />
                        Sie können sich auf dieser Website registrieren, um zusätzliche Funktionen auf der Seite zu nutzen. Die dazu
                        eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie sich
                        registriert haben. Die bei der Registrierung abgefragten Pflichtangaben müssen vollständig angegeben werden.
                        Anderenfalls werden wir die Registrierung ablehnen.
                        <br />
                        <br />
                        Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die bei der
                        Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
                        <br />
                        <br />
                        Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt zum Zwecke der Durchführung des durch die
                        Registrierung begründeten Nutzungsverhältnisses und ggf. zur Anbahnung weiterer Verträge (Art. 6 Abs. 1 lit. b
                        DSGVO).
                        <br />
                        <br />
                        Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf dieser Website registriert
                        sind
                        und werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
                    </Paragraph>
                    <Headline
                        text={'5. Plugins und Tools'}
                    />
                    <Paragraph>
                        YouTube
                        <br />
                        <br />
                        Diese Website bindet Videos der Website YouTube ein. Betreiber der Website ist die Google Ireland Limited
                        („Google“),
                        Gordon House, Barrow Street, Dublin 4, Irland.
                        <br />
                        <br />
                        Wenn Sie eine unserer Webseiten besuchen, auf denen YouTube eingebunden ist, wird eine Verbindung zu den Servern von
                        YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
                        <br />
                        <br />
                        Des Weiteren kann YouTube verschiedene Cookies auf Ihrem Endgerät speichern oder vergleichbare Technologien zur
                        Wiedererkennung verwenden (z.B. Device-Fingerprinting). Auf diese Weise kann YouTube Informationen über Besucher
                        dieser Website erhalten. Diese Informationen werden u. a. verwendet, um Videostatistiken zu erfassen, die
                        Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen.
                        <br />
                        <br />
                        Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
                        persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.
                        <br />
                        <br />
                        Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt
                        ein
                        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt
                        wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
                        jederzeit widerrufbar.
                        <br />
                        <br />
                        Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter:
                        <a
                            href="https://policies.google.com/privacy?hl=de"
                            target="_blank"
                            rel="noopener noreferrer"
                        >https://policies.google.com/privacy?hl=de
                        </a>
                        .
                        <br />
                        <br />
                        <br />
                        Vimeo
                        <br />
                        <br />
                        Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die Vimeo Inc., 555 West 18th Street, New York, New
                        York 10011, USA.
                        <br />
                        <br />
                        Wenn Sie eine unserer mit einem Vimeo-Video ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von
                        Vimeo hergestellt. Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Zudem erlangt
                        Vimeo Ihre IP-Adresse. Dies gilt auch dann, wenn Sie nicht bei Vimeo eingeloggt sind oder keinen Account bei Vimeo
                        besitzen. Die von Vimeo erfassten Informationen werden an den Vimeo-Server in den USA übermittelt.
                        <br />
                        <br />
                        Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, ermöglichen Sie Vimeo, Ihr Surfverhalten direkt Ihrem persönlichen
                        Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem Vimeo-Account ausloggen.
                        <br />
                        <br />
                        Zur Wiedererkennung der Websitebesucher verwendet Vimeo Cookies bzw. vergleichbare Wiedererkennungstechnologien
                        (z.B.
                        Device-Fingerprinting).
                        <br />
                        <br />
                        Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
                        berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt
                        wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
                        jederzeit widerrufbar.
                        <br />
                        <br />
                        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission sowie nach Aussage von Vimeo
                        auf „berechtigte Geschäftsinteressen“ gestützt. Details finden Sie hier:
                        <a
                            href="https://vimeo.com/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >https://vimeo.com/privacy
                        </a>
                        .
                        <br />
                        <br />
                        Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Vimeo unter:
                        <a
                            href="https://vimeo.com/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >https://vimeo.com/privacy
                        </a>
                        .
                    </Paragraph>
                </div>

                <Footer
                    theme={FooterTheme.blue}
                />
            </div>
        );
    }
}

Screen.renderAffectingProps = [];

Screen.renderAffectingStates = [];

export default Screen;
