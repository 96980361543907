import activeWorkshopReducer from './activeWorkshop';
import alertBoxReducer       from './alertBox';
import countdownReducer      from './countdown';
import motivationReducer     from './motivation';
import semiometryReducer     from './semiometry';
import { combineReducers }   from 'redux';
import { connectRouter }     from 'connected-react-router';

export default (history) => combineReducers({
    activeWorkshop: activeWorkshopReducer,
    alertBox:       alertBoxReducer,
    countdown:      countdownReducer,
    motivation:     motivationReducer,
    semiometry:     semiometryReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
})