import AlertBoxManager from '../components/AlertBoxManager';
import ComponentHelper from '../helper/ComponentHelper';
import Footer          from '../components/Footer';
import FooterTheme     from '../components/Footer/FooterTheme';
import Header          from '../components/Header';
import I18n            from 'i18next';
import PageTitleHelper from '../helper/PageTitle';
import Headline        from '../components/Headline';
import Paragraph       from '../components/Paragraph';
import React           from 'react';
import { Component }   from 'react';
import styles          from '../styles.module.scss';
import { Helmet }      from 'react-helmet';

class Screen extends Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Screen,
            nextProps,
            nextState,
        );
    }

    render() {
        return (
            <div className={styles.main}>
                <Header />
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('home'))}
                    </title>
                </Helmet>
                <AlertBoxManager />

                <div className={styles.textContainer}>
                    <Headline
                        text={'Angaben gemäß § 5 TMG:'}
                    />
                    <Paragraph>
                        vergissmeinnicht GmbH
                        <br />
                        Münsterplatz 5
                        <br />
                        78462 Konstanz
                    </Paragraph>

                    <Paragraph>
                        Handelsregister: HRB 70 1876
                        <br />
                        Registergericht: Freiburg/Brsg.
                    </Paragraph>

                    <Headline
                        text={'Vertreten durch:'}
                    />
                    <Paragraph>
                        Achim Günter und Jens Schröder
                    </Paragraph>

                    <Headline
                        text={'Kontakt:'}
                    />
                    <Paragraph>
                        Telefon: <a href={'tel:+49 7531 922590-0'}>+49 7531 922590-0</a>
                        <br />
                        Telefax: <a href={'tel:+49 7531 922590-9'}>+49 7531 922590-9</a>
                        <br />
                        E-Mail: <a href={'mailto:info@vergissmeinnicht-kommunikation.de'}>info@vergissmeinnicht-kommunikation.de</a>
                    </Paragraph>

                    <Headline
                        text={'Umsatzsteuer-ID'}
                    />
                    <Paragraph>
                        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
                        <br />
                        DE258581074
                    </Paragraph>

                    <Headline
                        text={'Redaktionell Verantwortlicher'}
                    />
                    <Paragraph>
                        Achim Günter und Jens Schröder
                    </Paragraph>

                    <Headline
                        text={'Entwicklung'}
                    />
                    <Paragraph>
                        Lulububu Software GmbH
                        <br />
                        <a
                            href="https://lulububu.de"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Web App Agentur Ravensburg
                        </a>
                    </Paragraph>

                    <Headline
                        text={'EU-Streitschlichtung:'}
                    />
                    <Paragraph>
                        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
                        href="https://ec.europa.eu/consumers/odr/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://ec.europa.eu/consumers/odr/
                    </a>.
                        <br />
                        Unsere E-Mail-Adresse finden Sie oben im Impressum.
                    </Paragraph>

                    <Headline
                        text={'Verbraucherstreitbeilegung/Universalschlichtungsstelle'}
                    />
                    <Paragraph>
                        Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Zuständig ist die Universalschlichtungsstelle des Zentrums für Schlichtung e.V., Straßburger Straße 8, 77694 Kehl am Rhein (
                        <a
                            href="https://www.verbraucher-schlichter.de"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            https://www.verbraucher-schlichter.de
                        </a>
                        ).
                    </Paragraph>

                    <Headline
                        text={'Haftung für Inhalte'}
                    />
                    <Paragraph>
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
                        oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                    </Paragraph>
                    <Paragraph>
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
                        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    </Paragraph>

                    <Headline
                        text={'Haftung für Links'}
                    />
                    <Paragraph>
                        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                    </Paragraph>
                    <Paragraph>
                        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                    </Paragraph>

                    <Headline
                        text={'Urheberrecht'}
                    />
                    <Paragraph>
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                        Gebrauch gestattet.
                    </Paragraph>
                    <Paragraph>
                        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                    </Paragraph>
                </div>
                <Footer
                    theme={FooterTheme.blue}
                />
            </div>
        );
    }
}

Screen.renderAffectingProps = [];

Screen.renderAffectingStates = [];

export default Screen;
