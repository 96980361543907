import React     from 'react';
import _         from 'lodash';
import Cast      from '../../helper/Cast';
import Icon      from '../../components/Icon';
import IconType  from '../Icon/IconType';
import PropTypes from '../PropTypes';
import styles    from './styles.module.scss';

class SelectInput extends React.Component {
    render() {
        const isRealValueSelected = Cast.bool(this.props.selectedValue);

        return (
            <div className={styles.selectInputContainer}>
                <select
                    className={styles.selectInput}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    defaultValue={this.props.selectedValue}
                >
                    <option disabled={isRealValueSelected}>
                        {this.props.placeholder}
                    </option>
                    {this.props.options.map(this.renderOption)}
                </select>
                {this.renderIcon()}
            </div>
        );
    }

    renderOption = (option, index) => {
        const isSelected = (
            this.props.selectedValue &&
            this.props.selectedValue.iri === option.iri
        );

        return (
            <option
                key={`select-option-${index}`}
                value={option.iri}
                selected={isSelected}
            >
                {option.title}
            </option>
        );
    };

    renderIcon() {
        if (this.props.icon) {
            return (
                <Icon
                    icon={this.props.icon}
                />
            );
        }

        return null;
    }
}

const Component = SelectInput;

Component.propTypes = {
    icon:          PropTypes.oneOf(Object.values(IconType)),
    onChange:      PropTypes.func,
    options:       PropTypes.array,
    placeholder:   PropTypes.string,
    selectedValue: PropTypes.string,
};

Component.defaultProps = {
    icon:          '',
    onChange:      _.noop,
    options:       [],
    placeholder:   '',
    selectedValue: null,
};

export default Component;
