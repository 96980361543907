import * as Api               from '../../api';
import I18n                   from 'i18next';
import Routes                 from '../../constants/Routes';
import { AlertBoxActions }    from '../actions/alertBox';
import { call }               from 'redux-saga/effects';
import { ParticipantActions } from '../actions/participant';
import { push }               from 'connected-react-router';
import { put }                from 'redux-saga/effects';
import { select }             from 'redux-saga/effects';

const createParticipant = function* ({ name, email, department }) {
    const workshop = yield select(state => state.activeWorkshop.activeWorkshop);
    const response = yield call(
        Api.createParticipant,
        name,
        email,
        department,
        workshop.customer.iri,
        workshop.iri,
        window.navigator.userAgent,
        `${window.innerWidth}x${window.innerHeight}`,
    );

    if (response.ok) {
        const participant = response.data;

        yield put(ParticipantActions.createParticipantSucceeded({
            participant,
        }));
        yield put(push(Routes.home));
    } else {
        const error = response.data['hydra:description'].replace('name: ', '');

        yield put(ParticipantActions.createParticipantFailed({
            error:       'error_participant',
            description: I18n.t(error),
        }));
    }
};

const createParticipantFailed = function* ({ error, description }) {
    yield put(AlertBoxActions.showErrorAlert({
        text: description,
    }));
};

export default {
    createParticipant,
    createParticipantFailed,
};
