import React                  from 'react';
import _                      from 'lodash';
import AnswerTypes            from '../constants/AnswerTypes';
import classNames             from 'classnames';
import ComponentHelper        from '../helper/ComponentHelper';
import ContextQuestion        from '../components/ContextQuestion';
import Header                 from '../components/Header';
import I18n                   from 'i18next';
import MotivationTypes        from '../constants/MotivationTypes';
import MultipleChoiceSelector from '../components/MultipleChoiceSelector';
import PageTitleHelper        from '../helper/PageTitle';
import StateHelper            from '../helper/State';
import styles                 from '../styles.module.scss';
import VideoOverlay           from '../components/VideoOverlay';
import { bindActionCreators } from 'redux';
import { Component }          from 'react';
import { connect }            from 'react-redux';
import { Helmet }             from 'react-helmet';
import { MotivationActions }  from '../store/actions/motivation';
import { WorkshopActions }    from '../store/actions/activeWorkshop';

class Screen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            justAnswered: false,
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Screen,
            nextProps,
            nextState,
        );
    }

    getMotivationType = () => {
        let motivationType = null;

        if (this.props.currentAnsweringType === AnswerTypes.currentMotivationAnswers) {
            motivationType = MotivationTypes.actual;
        } else if (this.props.currentAnsweringType === AnswerTypes.futureMotivationAnswers) {
            motivationType = MotivationTypes.target;
        }

        return motivationType;
    };

    isSelected = () => {
        return _.get(this.props.motivationCategory, 'isAnswered', false);
    };

    valueChanged = (index, motivation, selected) => {
        this.setState({ justAnswered: true });

        const newMotivation          = _.cloneDeep(motivation);
        newMotivation.motivationType = this.getMotivationType();

        this.props.saveMotivationAnswer({
            index:      index,
            motivation: newMotivation,
            selected:   selected,
        });
    };

    getProgress = () => {
        let completed         = [];
        const motivationDatas = _.map(this.props.motivationCategories, 'motivationDatas');

        motivationDatas.forEach(motivationCategory => {
            const selected = _.some(motivationCategory, (answer) => {
                return answer.selected;
            });

            if (selected) {
                completed.push(motivationCategory);
            }
        });

        return Math.round(completed.length / this.props.motivationCategories.length * 100);
    };

    getRightTitle = () => {
        switch (this.props.currentAnsweringType) {
            case 'currentMotivationAnswers':
                return I18n.t('statusQuo');
            case 'futureMotivationAnswers':
                return I18n.t('inFuture');
        }

        return null;
    };

    render() {
        return (
            <div
                className={classNames(
                    styles.main,
                    styles.noFooter,
                )}
            >
                <Header />
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('motives'))}
                    </title>
                </Helmet>
                <div className={styles.container}>
                    <ContextQuestion />
                    {this.renderContent()}
                </div>
            </div>
        );
    }

    renderContent = () => {
        if (this.props.watchedVideo) {
            const isSelected = this.isSelected();

            return (
                <MultipleChoiceSelector
                    attribute={this.props.motivationCategory}
                    disabled={isSelected && !this.state.justAnswered}
                    allowContinue={isSelected}
                    progress={this.getProgress()}
                    valueChanged={this.valueChanged}
                />
            );
        }

        return (
            <VideoOverlay
                answeringType={this.props.currentAnsweringType}
                videoEnded={this.videoEnded}
            />
        );
    };

    videoEnded = () => {
        this.props.videoFinished();
    };
}

Screen.renderAffectingProps = [
    'currentAnsweringType',
    'currentAnswerIndex',
    'motivationCategory',
    'watchedVideo',
];

Screen.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        currentAnsweringType: _.get(state.activeWorkshop, 'currentAnswerState.answeringType', null),
        currentAnswerIndex:   _.get(state.activeWorkshop, 'currentAnswerState.answerIndex', null),
        motivationCategories: StateHelper.getCurrentUserAnswers(
            state,
            _.get(state.activeWorkshop, 'currentAnswerState.answeringType', null),
            null,
            [],
        ),
        motivationCategory:   StateHelper.getCurrentUserAnswers(
            state,
            _.get(state.activeWorkshop, 'currentAnswerState.answeringType', null),
            _.get(state.activeWorkshop, 'currentAnswerState.answerIndex', null),
            {},
        ),
        watchedVideo:         _.get(
            state.activeWorkshop,
            [
                'userAnswers',
                _.get(state.activeWorkshop, 'currentAnswerState.answeringType', null),
                'watchedVideo',
            ],
            false,
        ),
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    Object.assign(
        MotivationActions,
        WorkshopActions,
    ),
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
