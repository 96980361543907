import _               from 'lodash';
import classNames      from 'classnames';
import ComponentHelper from '../../helper/ComponentHelper';
import I18n            from 'i18next';
import PropTypes       from '../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import Title           from '../Title';
import {connect}       from 'react-redux';

class UploadLoadingOverlay extends React.Component {
    render() {
        const {
            isPosting,
            userAnswersPostedCount,
            userAnswersCount
        } = this.props;

        return (
            <div className={classNames(
                styles.uploadLoadingOverlayContainer,
                {
                    [styles.uploadLoadingOverlayContainerVisible]: isPosting,
                }
            )}>
                <div className={styles.uploadLoadingOverlay}>
                    <Title
                        text={I18n.t('uploadingData')}
                        uppercase={true}
                    />
                    <p>
                        {userAnswersPostedCount} / {userAnswersCount}
                    </p>
                    <div className={styles.uploadLoadingOverlayTrack}>
                        <div
                            className={styles.uploadLoadingOverlayTrackBar}
                            style={{ width: `${(100 * userAnswersPostedCount) / userAnswersCount}%` }}
                        />
                    </div>
                </div>
                <div className={styles.uploadLoadingOverlayBackdrop}>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = UploadLoadingOverlay;

Component.propTypes = {
    isPosting:              PropTypes.bool,
    userAnswersCount:       PropTypes.number,
    userAnswersPostedCount: PropTypes.number,
};

Component.defaultProps = {
    isPosting:              false,
    userAnswersCount:       0,
    userAnswersPostedCount: 0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => {
    const userData    = [];
    const userAnswers = _.get(state, 'activeWorkshop.userAnswers');
    const isPosting   = _.get(state, 'activeWorkshop.activeWorkshop.isPosting');

    // Getting all the raw data from the categories
    Object.keys(userAnswers).forEach((answerType) => userData.push(...userAnswers[answerType].data));

    // Find answers that have already been posted
    const userAnswersPostedCount = _.filter(userData, (data) => data.sent || _.filter(data.motivationDatas, {
        selected: true,
        sent:     true
    }).length).length;
    const userAnswersCount       = userData.length;

    return {
        isPosting,
        userAnswersCount,
        userAnswersPostedCount,
    }
};


export default connect(
    mapStateToProps,
    null,
)(Component);
