import I18n from 'i18next';

export default [
    {
        title: I18n.t('veryInappropriate'),
        value: -3,
    },
    {
        title: I18n.t('inappropriate'),
        value: -2,
    },
    {
        title: I18n.t('ratherInappropriate'),
        value: -1,
    },
    {
        title: I18n.t('eitherAppropriateOrInappropriate'),
        value: 0,
    },
    {
        title: I18n.t('ratherAppropriate'),
        value: 1,
    },
    {
        title: I18n.t('appropriate'),
        value: 2,
    },
    {
        title: I18n.t('veryAppropriate'),
        value: 3,
    },
];
