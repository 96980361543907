import _                      from 'lodash';
import ComponentHelper        from '../../helper/ComponentHelper';
import PropTypes              from '../PropTypes';
import React                  from 'react';
import styles                 from './styles.module.scss';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { CountdownActions }   from '../../store/actions/countdown';

class Countdown extends React.Component {
    interval = null;

    componentDidMount () {
        this.startCountdown();
    }

    componentWillUnmount () {
        this.pauseCountdown();
    }

    countdown = () => {
        this.props.countdown();
    };

    getFormattedCountdown () {
        const countdownInSeconds      = this.props.countdownValue;
        const countdownInMilliseconds = countdownInSeconds > 0 ? countdownInSeconds * 1000 : 0;

        return new Date(countdownInMilliseconds).toISOString().substr(14, 5);
    }

    pauseCountdown () {
        clearInterval(this.interval);
    }

    render () {
        if (this.props.countdownValue > -1) {
            const countdown = this.getFormattedCountdown();

            return (
                <div className={styles.countdown}>
                    {countdown}
                </div>
            );
        }

        return null;
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    startCountdown = () => {
        this.interval = setInterval(this.countdown, 1000);
    };
}

const Component = Countdown;

Component.propTypes = {
    countdownValue: PropTypes.number,
};

Component.defaultProps = {
    countdownValue: 0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        countdownValue: _.get(state, 'countdown.countdown'),
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(CountdownActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);