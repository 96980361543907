import { all }        from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

// Types
import { LOCATION_CHANGE }  from 'connected-react-router';
import { MotivationTypes }  from '../actions/motivation';
import { ParticipantTypes } from '../actions/participant';
import { REHYDRATE }        from 'redux-persist';
import { SemiometryTypes }  from '../actions/semiometry';
import { WorkshopTypes }    from '../actions/activeWorkshop';

// Actions

// Sagas
import MotivationSaga         from './motivation';
import NavigationSaga         from './navigation';
import ParticipantActionSagas from './participantAction';
import ParticipantSagas       from './participant';
import SemiometrySaga         from './semiometry';
import WorkshopSagas          from './activeWorkshop';

const root = function* () {
    yield all([
        // @formatter:off
        takeLatest([LOCATION_CHANGE, REHYDRATE],                   NavigationSaga.locationChange),

        takeLatest([WorkshopTypes.FETCH_WORKSHOP],                 WorkshopSagas.fetchWorkshop),
        takeLatest([WorkshopTypes.FETCH_WORKSHOP_FAILED],          WorkshopSagas.fetchWorkshopFailed),
        takeLatest([WorkshopTypes.FETCH_WORKSHOPS],                WorkshopSagas.fetchWorkshops),
        takeLatest([WorkshopTypes.FETCH_WORKSHOPS_FAILED],         WorkshopSagas.fetchWorkshopsFailed),
        takeLatest([WorkshopTypes.CLONE_QUESTIONS],                WorkshopSagas.cloneQuestions),
        takeLatest([WorkshopTypes.FINISH_ANSWERING],               WorkshopSagas.finishAnswering),
        takeLatest([WorkshopTypes.POST_WORKSHOP_DATA],             WorkshopSagas.postWorkshopData),
        takeLatest([WorkshopTypes.POST_WORKSHOP_DATA_SUCCESS],     WorkshopSagas.postWorkshopDataSucceeded),

        takeLatest([ParticipantTypes.CREATE_PARTICIPANT],          ParticipantSagas.createParticipant),
        takeLatest([ParticipantTypes.CREATE_PARTICIPANT_FAILED],   ParticipantSagas.createParticipantFailed),

        takeLatest([MotivationTypes.FETCH_MOTIVATION],             MotivationSaga.fetchMotivations),
        takeLatest([MotivationTypes.FETCH_MOTIVATION_FAILED],      MotivationSaga.fetchMotivationsFailed),
        takeLatest([MotivationTypes.POST_MOTIVATION_DATA],         MotivationSaga.postMotivations),
        takeLatest([MotivationTypes.POST_MOTIVATION_DATA_FAILED],  MotivationSaga.postMotivationsFailed),

        takeLatest([SemiometryTypes.FETCH_SEMIOMETRY],             SemiometrySaga.fetchSemiometries),
        takeLatest([SemiometryTypes.FETCH_SEMIOMETRY_FAILED],      SemiometrySaga.fetchSemiometriesFailed),
        takeLatest([SemiometryTypes.POST_SEMIOMETRY_DATA],         SemiometrySaga.postSemiometries),
        takeLatest([SemiometryTypes.POST_SEMIOMETRY_DATA_FAILED],  SemiometrySaga.postSemiometriesFailed),

        takeLatest([
            WorkshopTypes.START_ANSWERING,
            WorkshopTypes.FINISH_ANSWERING,
            WorkshopTypes.VIDEO_FINISHED,
            WorkshopTypes.POST_WORKSHOP_DATA,
            WorkshopTypes.POST_WORKSHOP_DATA_FAILED,
            WorkshopTypes.POST_WORKSHOP_DATA_SUCCESS,
        ],                                                         ParticipantActionSagas.participantAction),

        takeLatest([
            MotivationTypes.SAVE_MOTIVATION_ANSWER,
            SemiometryTypes.SAVE_SEMIOMETRY_ANSWER,
        ],                                                         ParticipantActionSagas.saveRedux),

        takeLatest([SemiometryTypes.SAVE_SEMIOMETRY_ANSWER],       SemiometrySaga.postSemiometries),

        takeLatest([WorkshopTypes.CHANGE_CURRENT_INDEX],           MotivationSaga.postMotivations),
        // @formatter:on
    ]);
};

const startUp = function* () {
    yield preLoading();
};

const preLoading = function* () {
};

export default {
    root,
    startUp,
};
