import PropTypes from '../PropTypes';
import React     from 'react';
import styles    from './styles.module.scss';

class Progress extends React.Component {
    render () {
        return (
            <div className={styles.progressContainer}>
                <div className={styles.progress}>
                    <div
                        className={styles.percentage}
                        style={{ width: this.props.percentage + '%' }}
                    />
                </div>
                <span className={styles.progressText}>
                    {this.props.percentage} %
                </span>
            </div>
        );
    }
}

const Component = Progress;

Component.propTypes = {
    percentage: PropTypes.number,
};

Component.defaultProps = {
    percentage: 0,
};

export default Component;