import Cast               from '../../helper/Cast';
import update             from 'immutability-helper';
import { CountdownTypes } from '../actions/countdown';

const firstCountdownInMinutes     = 15;
const followingCountdownInMinutes = 10;
const firstCountdown              = Cast.int(firstCountdownInMinutes * 60);
const followingCountdown          = Cast.int(followingCountdownInMinutes * 60);

const initialState = {
    countdown:               firstCountdown,
    firstCountdownEnded:     false,
    followingCountdownEnded: false,
};

function getCountdownValues (state) {
    const countdownValues = Object.assign({}, state);

    if (countdownValues.countdown >= 1) {
        --countdownValues.countdown;
    } else if (!countdownValues.firstCountdownEnded && !countdownValues.followingCountdownEnded) {
        countdownValues.firstCountdownEnded = true;
        countdownValues.countdown           = followingCountdown;
    } else {
        countdownValues.countdown               = -1;
        countdownValues.followingCountdownEnded = true;
    }

    return countdownValues;
}

const countdownTick = (action, state) => {
    const {
              countdown,
              firstCountdownEnded,
              followingCountdownEnded,
          } = getCountdownValues(state);

    return update(state, {
        countdown:               {
            $set: countdown,
        },
        firstCountdownEnded:     {
            $set: firstCountdownEnded,
        },
        followingCountdownEnded: {
            $set: followingCountdownEnded,
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case CountdownTypes.COUNTDOWN_TICK: return countdownTick(action, state);
        default:                            return state;
        // @formatter:on
    }
}