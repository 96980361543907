import { makeActionCreator } from '../../helper/Store';

export const CountdownTypes = {
    COUNTDOWN_TICK: 'Countdown/COUNTDOWN_TICK',
};

const countdown = makeActionCreator(
    CountdownTypes.COUNTDOWN_TICK,
);

export const CountdownActions = {
    countdown,
};