import React       from 'react';
import _           from 'lodash';
import I18n        from 'i18next';
import PropTypes   from '../PropTypes';
import styles      from './styles.module.scss';
import { connect } from 'react-redux';

class ContextQuestion extends React.Component {
    getContextQuestion = () => {
        if (this.props.contextQuestion) {
            return this.props.contextQuestion;
        }

        return I18n.t(
            'fallbackContextQuestion',
            {
                companyName: this.props.companyName,
            },
        );
    };

    render() {
        return (
            <h1 className={styles.contextQuestion}>
                {this.getContextQuestion()}
            </h1>
        );
    }
}

const Component = ContextQuestion;

Component.propTypes = {
    companyName:     PropTypes.string,
    contextQuestion: PropTypes.string,
};

Component.default = {
    companyName:     null,
    contextQuestion: null,
};

const mapStateToProps = state => (
    {
        companyName:     _.get(state.activeWorkshop, 'activeWorkshop.customer.title', null),
        contextQuestion: _.get(state.activeWorkshop, 'activeWorkshop.contextQuestion', null),
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);