import React                        from 'react';
import _                            from 'lodash';
import classNames                   from 'classnames';
import ColorButton                  from '../ColorButton';
import ComponentHelper              from '../../helper/ComponentHelper';
import I18n                         from 'i18next';
import MultipleChoiceSelectorChoice from '../MultipleChoiceSelectorChoice';
import Progress                     from '../Progress';
import PropTypes                    from '../PropTypes';
import StateHelper                  from '../../helper/State';
import styles                       from './styles.module.scss';
import Title                        from '../Title';
import TitleTheme                   from '../Title/TitleTheme';
import { bindActionCreators }       from 'redux';
import { connect }                  from 'react-redux';
import { WorkshopActions }          from '../../store/actions/activeWorkshop';

class MultipleChoiceSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            skipAnswerSelected: false,
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const answers     = _.get(this.props.attribute, 'motivationDatas', []);
        const skipAnswers = _.get(_.filter(answers, { skipAnswer: true }), 0);

        if (skipAnswers) {
            this.setState({
                skipAnswerSelected: skipAnswers.selected,
            });
        }
    }

    attributePressed = (index, attribute, selected) => {
        this.props.valueChanged(index, attribute, selected);
    };

    hasSelectedAnswer = () => {
        return _.filter(
            _.get(this.props.attribute, 'motivationDatas', []),
            {
                skipAnswer: false,
                selected:   true,
            },
        ).length;
    };

    nextAttribute = () => {
        window.scrollTo(0, 0);

        this.props.changeCurrentIndex({
            index: this.props.currentAnswerIndex + 1,
        });
    };

    finishAnswering = () => {
        this.props.finishAnswering();
    };

    isLastPage = () => {
        return this.props.currentAnswerIndex + 1 === this.props.motivationAnswersCount;
    };

    render() {
        const answers = _.get(this.props.attribute, 'motivationDatas', []);

        return (
            <div className={styles.multipleChoiceSelectorContainer}>
                <div className={styles.multipleChoiceSelector}>
                    <div className={styles.multipleChoiceSelectorTitleContainer}>
                        <Title
                            prefix={this.props.titlePrefix}
                            text={this.props.attribute.title}
                            theme={TitleTheme.main}
                        />
                        <Title
                            text={this.props.rightTitle}
                            theme={TitleTheme.main}
                        />
                    </div>
                    <div
                        id={'motives-active'}
                        className={styles.multipleChoiceSelectorItems}
                    >
                        {answers.map(this.renderAnswerItem)}
                    </div>
                    <div className={styles.multipleChoiceSelectorSkipAnswerContainer}>
                        {this.renderSkipAnswerButton(answers)}
                    </div>
                </div>
                <div className={styles.multipleChoiceSelectorButtonContainer}>
                    {this.renderButton()}
                    <div className={styles.multipleChoiceSelectorProgressContainer}>
                        <Progress percentage={this.props.progress} />
                    </div>
                </div>
            </div>
        );
    }

    renderAnswerItem = (attribute, index) => {
        if (!attribute.skipAnswer) {
            const isSelected = _.get(attribute, 'selected', false);

            return (
                <MultipleChoiceSelectorChoice
                    key={'multiPointSelectorChoice' + index}
                    attribute={attribute}
                    active={isSelected}
                    disabled={this.props.disabled || this.state.skipAnswerSelected}
                    onClick={() => {
                        this.attributePressed(index, attribute, !isSelected);
                    }}
                />
            );
        }

        return null;
    };

    renderButton = () => {
        if (this.props.progress === 100 && this.isLastPage()) {
            return (
                <ColorButton
                    id={'motives-next'}
                    text={I18n.t('finish') + ' >'}
                    onClick={this.finishAnswering}
                    shadow={true}
                />
            );
        }

        return (
            <ColorButton
                id={'motives-next'}
                text={I18n.t('more') + ' >'}
                disabled={!this.props.allowContinue}
                onClick={this.nextAttribute}
                shadow={true}
            />
        );
    };

    renderSkipAnswerButton = (answers) => {
        const skipAnswerIndex = _.keys(_.pickBy(answers, { skipAnswer: true }));
        const skipAnswers     = _.get(_.filter(answers, { skipAnswer: true }), 0);

        if (skipAnswers) {
            return (
                <button
                    className={classNames(
                        styles.multipleChoiceSelectorSkipAnswer,
                        {
                            [styles.multipleChoiceSelectorSkipAnswerActive]: skipAnswers.selected,
                        },
                    )}
                    onClick={() => {
                        this.attributePressed(skipAnswerIndex, skipAnswers, !this.props.allowContinue);
                    }}
                    disabled={this.hasSelectedAnswer()}
                >
                    {I18n.t('skipAnswer')}
                </button>
            );
        }

        return null;
    };
}

const Component = MultipleChoiceSelector;

Component.propTypes = {
    allowContinue: PropTypes.bool,
    attribute:     PropTypes.object,
    disabled:      PropTypes.bool,
    nextAttribute: PropTypes.func,
    progress:      PropTypes.number,
    rightTitle:    PropTypes.string,
    titlePrefix:   PropTypes.string,
    valueChanged:  PropTypes.func,
};

Component.defaultProps = {
    allowContinue: false,
    attribute:     {},
    disabled:      false,
    nextAttribute: _.noop,
    progress:      0,
    rightTitle:    null,
    titlePrefix:   null,
    valueChanged:  _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'skipAnswerSelected',
];

const mapStateToProps = state => (
    {
        currentAnswerIndex:     _.get(state.activeWorkshop, 'currentAnswerState.answerIndex', null),
        motivationAnswersCount: StateHelper.getCurrentUserAnswers(
            state,
            _.get(state.activeWorkshop, 'currentAnswerState.answeringType', null),
            null,
            0,
        ).length,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(WorkshopActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
