import { makeActionCreator } from '../../helper/Store';

export const AlertBoxTypes = {
    CLEAR:        'AlertBox/CLEAR',
    CLEAR_SINGLE: 'AlertBox/CLEAR_SINGLE',
    SHOW_ERROR:   'AlertBox/SHOW_ERROR',
    SHOW_SUCCESS: 'AlertBox/SHOW_SUCCESS',
};

const clearAlerts = makeActionCreator(
    AlertBoxTypes.CLEAR,
);

const clearSingleAlert = makeActionCreator(
    AlertBoxTypes.CLEAR_SINGLE,
    {
        index: null,
    },
);

const showErrorAlert = makeActionCreator(
    AlertBoxTypes.SHOW_ERROR,
    {
        text: null,
    },
);

const showSuccessAlert = makeActionCreator(
    AlertBoxTypes.SHOW_SUCCESS,
    {
        text: null,
    },
);

export const AlertBoxActions = {
    clearAlerts,
    clearSingleAlert,
    showErrorAlert,
    showSuccessAlert,
};