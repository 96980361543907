import _                           from 'lodash';
import ComponentHelper             from '../../helper/ComponentHelper';
import DashboardItem               from '../../components/DashboardItem';
import I18n                        from 'i18next';
import LoadingIndicatorColorButton from '../../components/LoadingIndicatorColorButton';
import PropTypes                   from '../PropTypes';
import React                       from 'react';
import styles                      from './styles.module.scss';
import Title                       from '../../components/Title';
import TitleTheme                  from '../Title/TitleTheme';
import { connect }                 from 'react-redux';

class OverviewDashboard extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    getCustomerName = () => {
        return _.get(this.props.activeWorkshop, 'customer.title', '');
    };

    render () {
        return (
            <div className={styles.overviewDashboardContainer}>
                <div className={styles.overviewDashboard}>
                    <Title
                        text={this.getCustomerName()}
                        theme={TitleTheme.main}
                        uppercase={true}
                    />
                    <div className={styles.overviewDashboardItems}>
                        {this.props.cards.map(this.renderDashboardItem)}
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <LoadingIndicatorColorButton
                        text={I18n.t('send')}
                        disabled={this.props.stepsDone < this.props.cards.length || this.props.isFinished}
                        onClick={this.props.sendButtonPressed}
                        shadow={true}
                    />
                </div>
            </div>
        );
    }

    renderDashboardItem = (item, index) => {
        return (
            <DashboardItem
                title={item.title}
                text={item.text}
                icon={item.icon}
                route={item.route}
                answerType={item.answerType}
                key={'dashboardOverviewItem-' + index}
                active={this.props.stepsDone >= index + 1}
                disabled={this.props.isFinished || this.props.stepsDone < index}
            />
        );
    };
}

const Component = OverviewDashboard;

Component.propTypes = {
    activeWorkshop:    PropTypes.object,
    cards:             PropTypes.array,
    isFinished:        PropTypes.bool,
    sendButtonPressed: PropTypes.func,
    stepsDone:         PropTypes.number,
};

Component.defaultProps = {
    activeWorkshop:    {},
    cards:             [],
    isFinished:        false,
    sendButtonPressed: _.noop,
    stepsDone:         0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        activeWorkshop: _.get(state.activeWorkshop, 'activeWorkshop', {}),
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);