import * as Api   from '../../api';
import { call }   from 'redux-saga/effects';
import { select } from 'redux-saga/effects';
import I18n       from 'i18next';

const participantAction = function* (action) {
    const participant    = yield select(state => state.activeWorkshop.activeWorkshop.participant.iri);
    const translatedType = I18n.t(action.type);

    yield call(
        Api.createParticipantActionLog,
        participant,
        translatedType,
    );
};

const saveRedux = function* () {
    const participant    = yield select(state => state.activeWorkshop.activeWorkshop.participant.iri);
    const activeWorkshop = yield select(state => state.activeWorkshop);

    yield call(
        Api.updateParticipant,
        participant,
        JSON.stringify(activeWorkshop),
    );
};

export default {
    participantAction,
    saveRedux,
};
