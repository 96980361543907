import * as ApiUrls from '../constants/Api';
import { create }   from 'apisauce';
import Hydra        from '../helper/Hydra';

const createInstance = (host, apiPath) => {
    let api = null;

    api = create({
        baseURL: `${host}${apiPath}`,
        timeout: 10000,
        headers: {
            'Content-Type': 'application/json',
            'accept':       'application/ld+json',
        },
    });

    api.host = host;

    api.addResponseTransform((response) => {
        if (response.ok) {
            response.data = Hydra.cleanupObject(response.data);
        }

        return response;
    });

    return api;
};

const Api = createInstance(ApiUrls.BACKEND_URL, '/');

const fetchWorkshop = (id) => {
    return Api.get(ApiUrls.API_WORKSHOP_URL + '/' + id);
};

const fetchWorkshops = (id) => {
    return Api.get(ApiUrls.API_WORKSHOP_URL);
};

const fetchMotivations = () => {
    return Api.get(ApiUrls.API_MOTIVATION_URL);
};

const fetchSemiometries = () => {
    return Api.get(ApiUrls.API_SEMIOMETRY_URL);
};

const createParticipant = (
    name,
    email,
    department,
    customer,
    workshop,
    userAgent,
    displaySize,
) => {
    return Api.post(
        ApiUrls.API_PARTICIPANT_URL,
        {
            name,
            email,
            department,
            customer,
            workshop,
            userAgent,
            displaySize,
        },
    );
};

const updateParticipant = (
    iri,
    reduxState,
) => {
    return Api.put(
        iri,
        {
            reduxState,
        },
    );
};

const createParticipantActionLog = (
    participant,
    action,
) => {
    return Api.post(
        ApiUrls.API_PARTICIPANT_ACTION_LOG_URL,
        {
            participant,
            action,
        },
    );
};

const postSemiometry = (
    participant,
    workshop,
    semiometryData,
    value,
) => {
    return Api.post(
        ApiUrls.API_PARTICIPANT_SEMIOMETRY_URL,
        {
            participant,
            workshop,
            semiometryData,
            value,
        },
    );
};

const postMotivation = (
    participant,
    workshop,
    motivationData,
    type,
) => {
    return Api.post(
        ApiUrls.API_PARTICIPANT_MOTIVATION_URL,
        {
            participant,
            workshop,
            motivationData,
            type,
        },
    );
};

const postWorkshopFinished = (
    participant,
    workshop,
) => {
    return Api.post(
        ApiUrls.API_WORKSHOP_FINISHED_URL,
        {
            participant,
            workshop,
        },
    );
};

export {
    Api,
    fetchWorkshop,
    fetchWorkshops,
    fetchSemiometries,
    fetchMotivations,
    createParticipant,
    updateParticipant,
    createParticipantActionLog,
    postSemiometry,
    postMotivation,
    postWorkshopFinished,
};
