import React             from 'react';
import _                 from 'lodash';
import Icon              from '../../components/Icon';
import IconType          from '../Icon/IconType';
import PropTypes         from '../PropTypes';
import { DebounceInput } from 'react-debounce-input';
import styles            from './styles.module.scss';

class TextInput extends React.Component {
    render() {
        const value = this.props.value ? this.props.value : '';

        return (
            <div className={styles.textInputContainer}>
                <DebounceInput
                    className={styles.textInput}
                    placeholder={this.props.placeholder}
                    type={this.props.type}
                    value={value}
                    onChange={this.props.onChange}
                    debounceTimeout={300}
                    autoFocus={this.props.focus}
                />
                {this.renderIcon()}
            </div>
        );
    }

    renderIcon() {
        if (this.props.icon) {
            return (
                <Icon
                    icon={this.props.icon}
                />
            );
        }

        return null;
    }
}

const Component = TextInput;

Component.propTypes = {
    focus:       PropTypes.bool,
    icon:        PropTypes.oneOf(Object.values(IconType)),
    onChange:    PropTypes.func,
    placeholder: PropTypes.string,
    type:        PropTypes.string,
    value:       PropTypes.string,
};

Component.defaultProps = {
    focus:       false,
    icon:        '',
    onChange:    _.noop,
    placeholder: '',
    type:        'text',
    value:       '',
};

export default Component;
