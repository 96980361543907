import I18n from 'i18next';

class PageTitleHelper {
    static getPageTitle (prefix) {
        const pageTitle = prefix + ' - ' + I18n.t('pageTitle');

        return pageTitle;
    }
}

export default PageTitleHelper;