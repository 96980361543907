import '@babel/polyfill';
import './language/Language';
import * as serviceWorker  from './serviceWorker';
import Home                from './screens/Home';
import Imprint             from './screens/Imprint';
import Login               from './screens/Login';
import Motive              from './screens/Motive';
import NotFound            from './screens/NotFound';
import Privacy             from './screens/Privacy';
import React               from 'react';
import ReactDOM            from 'react-dom';
import Routes              from './constants/Routes';
import store               from './store';
import Values              from './screens/Values';
import { ConnectedRouter } from 'connected-react-router';
import { history }         from './store/index';
import { Provider }        from 'react-redux';
import { Route }           from 'react-router';
import { Switch }          from 'react-router';
import WindowWatch         from './components/WindowWatch';

ReactDOM.render(
    <Provider store={store()}>
        <ConnectedRouter history={history}>
            <WindowWatch />
            <Switch>
                <Route
                    exact
                    path={Routes.login}
                    component={Login}
                />
                <Route
                    exact
                    path={Routes.home}
                    component={Home}
                />
                <Route
                    exact
                    path={Routes.motive}
                    component={Motive}
                />
                <Route
                    exact
                    path={Routes.values}
                    component={Values}
                />
                <Route
                    exact
                    path={Routes.imprint}
                    component={Imprint}
                />
                <Route
                    exact
                    path={Routes.privacy}
                    component={Privacy}
                />
                <Route component={NotFound} />
            </Switch>
        </ConnectedRouter>
    </Provider>
    , document.getElementById('root'),
);

serviceWorker.register();
