import _               from 'lodash';
import AlertBoxType    from './AlertBoxType';
import classNames      from 'classnames';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import PropTypes       from '../PropTypes';
import React           from 'react';
import SelectionHelper from '../../helper/SelectionHelper';
import styles          from './styles.module.scss';

class AlertBox extends React.Component {
    render() {
        return (
            <div
                className={classNames(
                    styles.alertBox,
                    SelectionHelper.get(
                        this.props.type,
                        {
                            [AlertBoxType.error]:   styles.alertBoxError,
                            [AlertBoxType.success]: styles.alertBoxSuccess,
                        },
                    ),
                )}
            >
                <div className={styles.alertBoxIcon}>
                    {this.renderIconForType()}
                </div>
                <div className={styles.alertBoxText}>
                    {this.props.text}
                </div>
                {this.renderCloseButton()}
            </div>
        );
    }

    renderCloseButton = () => {
        if (this.props.onCloseButtonClick) {
            return (
                <button
                    className={styles.alertBoxCloseButton}
                    onClick={this.props.onCloseButtonClick}
                >
                    <Icon icon={IconType.cross} />
                </button>
            );
        }

        return null;
    };

    renderIconForType = () => {
        const iconType = SelectionHelper.get(
            this.props.type,
            {
                [AlertBoxType.error]:   IconType.cross,
                [AlertBoxType.success]: IconType.check,
            },
        );

        return (
            <Icon icon={iconType} />
        );
    };
}

const Component = AlertBox;

Component.propTypes = {
    onCloseButtonClick: PropTypes.func,
    text:               PropTypes.string,
    type:               PropTypes.oneOf(Object.values(AlertBoxType)),
};

Component.defaultProps = {
    onCloseButtonClick: _.noop,
    text:               '',
    type:               null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
