import _                from 'lodash';
import classNames       from 'classnames';
import ColorButtonTheme from './ColorButtonTheme';
import ComponentHelper  from '../../helper/ComponentHelper';
import Icon             from '../Icon';
import PropTypes        from '../PropTypes';
import React            from 'react';
import styles           from './styles.module.scss';
import IconType         from '../Icon/IconType';

class ColorButton extends React.Component {
    renderIcon = () => {
        if (this.props.icon) {
            return (
                <Icon icon={this.props.icon} />
            );
        }

        return null;
    };

    getTheme = () => {
        return classNames(
            styles.colorButton,
            {
                [styles.colorButtonHidden]:   this.props.hidden,
                [styles.colorButtonShadow]:   this.props.shadow,
                [styles.showIcon]:            this.props.icon,
                [styles.colorButtonInverted]: this.props.theme === ColorButtonTheme.inverted,
            },
        );
    };

    render() {
        const theme = this.getTheme();

        return (
            <button
                id={this.props.id}
                className={theme}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
            >

                <div
                    className={
                        classNames(
                            styles.iconContainer,
                            {
                                [styles.rotatingIcon]: this.props.iconIsRotating,
                            },
                        )}
                >
                    {
                        this.renderIcon()
                    }
                </div>
                {this.props.text}
            </button>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ColorButton;

Component.propTypes = {
    disabled:       PropTypes.bool,
    hidden:         PropTypes.bool,
    iconIsRotating: PropTypes.bool,
    IconType:       PropTypes.oneOf(Object.values(IconType)),
    id:             PropTypes.string,
    onClick:        PropTypes.func,
    shadow:         PropTypes.bool,
    text:           PropTypes.string,
    theme:          PropTypes.oneOf(Object.values(ColorButtonTheme)),
};

Component.defaultProps = {
    disabled:       false,
    hidden:         false,
    iconIsRotating: false,
    IconType:       null,
    id:             null,
    onClick:        _.noop,
    shadow:         false,
    text:           '',
    theme:          ColorButtonTheme.none,
};

Component.renderAffectingProps = [
    'disabled',
    'hidden',
    'iconIsRotating',
    'IconType',
    'id',
    'text',
];

Component.renderAffectingStates = [];

export default Component;
