import _                              from 'lodash';
import PropTypes                      from '../PropTypes';
import React                          from 'react';
import Routes                         from '../../constants/Routes';
import { connect }                    from 'react-redux';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import { withRouter }                 from 'react-router-dom';

class Link extends React.Component {
    render () {
        return (
            <ReactRouterDomLink
                className={this.props.className}
                disabled={this.props.disabled}
                to={this.props.to}
                onClick={this.props.onClick}
            >
                {this.props.children}
            </ReactRouterDomLink>
        );
    }
}

const Component = Link;

Component.propTypes = {
    children:  PropTypes.children,
    className: PropTypes.string,
    disabled:  PropTypes.bool,
    onClick:   PropTypes.func,
    to:        PropTypes.oneOf(Object.values(Routes)),
};

Component.default = {
    children:  [],
    className: '',
    disabled:  false,
    onClick:   _.noop,
    to:        Routes.home,
};

const mapStateToProps = state => (
    {
        pathname: state.router.location.pathname,
    }
);

export default withRouter(connect(
    mapStateToProps,
    null,
)(Component));